var config
var env = 'production'
// var env = 'production_test'
// var env = 'development'

config = {
  production: {
    apiUrl: 'https://mepproxbmt.com',
    imageUrl: 'https://mepproxbmt.com/'
  },
  development: {
    apiUrl: 'http://localhost:8000',
    imageUrl: 'http://localhost:8000/'

  }
};

export default config[env]
