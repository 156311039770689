import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Moment from 'moment'
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox'
import { TextField } from 'office-ui-fabric-react/lib/TextField'
import { Label } from 'office-ui-fabric-react/lib/Label'

import AutoCompleteInput from './AutoCompleteInput'
import CollectionArrayInput from './CollectionArrayInput'
import TagInput from './TagInput'
import SelectInput from './SelectInput'
import ImageCropper from './ImageCropper'
import FileInput from './FileInput'
import Wysiwyg1 from './Wysiwyg1'
import config from '../config'

const propTypes = {
  field: PropTypes.object,
  formData: PropTypes.object,
  validations: PropTypes.object,
  handleInputChange: PropTypes.func,
  handleFileInputChange: PropTypes.func,
  handleCollectionInputChange: PropTypes.func,
  hideLabel: PropTypes.bool,
  hideValidationMessage: PropTypes.bool
}

const defaultProps = {}

class FormInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: null
    };

  }

  componentWillMount() {
  }
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  getInputField = (props) => {
    const imageUrl = `${config.apiUrl}/uploads/`
    let { field, value, onEdit } = props
    switch (props.field.type) {
      case 'boolean':
        return (
          <Checkbox
            label={_.startCase(field.name) + ' ' + (field.required ? '*' : '')}
            checked={(value && typeof value === 'string' ? parseInt(value) : value) || this.state.value  || false}
            onChange={(e, value) => props.handleInputChange(value)}
            boxSide='start'
            className='checkbox'
          />
        )
      case 'collection':
        return (
          <AutoCompleteInput
            hideLabel={props.hideLabel}
            field={field}
            value={typeof value === 'string' ? parseInt(value) : value}
            selectedItem={this.props.selectedItem}
            onChanged={(e) => props.handleCollectionInputChange(e)}
            errorMessage={props.validations && props.validations.error}
            formData={props.formData}
            dynamicQuery={props.dynamicQuery}
          />
        )
      case 'wysiwyg':
        return (
          <Wysiwyg1 key={field.name}
            field={field}
            value={value}
            onEdit={onEdit}
            onChanged={(e) => props.handleInputChange(e)}
          />
        )
      case 'collectionArray':
        return (
          <CollectionArrayInput key={field.name}
            field={field}
            value={value}
            onChanged={(e) => props.handleInputChange(e)}
          />
        )
      case 'collectionArrayTag':
        return (
          <TagInput
            key={field.name}
            field={field}
            value={value}
            onChanged={(e) => props.handleInputChange(e)}
            errorMessage={props.validations && props.validations.error}
            formData={props.formData}
          />
        )
      case 'select':
        return (
          <SelectInput
            key={field.name}
            field={field}
            value={value || ''}
            options={field.options}
            onChanged={(e) => props.handleInputChange(e)}
            errorMessage={props.hideValidationMessage ? '' : (props.validations && props.validations.error)}
            disabled={props.field.disabled || false}
            dynamicQuery={props.dynamicQuery}
          />
        )
      case 'multiSelect':
        return (
          <SelectInput
            multiSelect={true}
            key={field.name}
            field={field}
            value={value || []}
            options={field.options}
            onChanged={(e) => props.handleInputChange(e)}
            errorMessage={props.hideValidationMessage ? '' : (props.validations && props.validations.error)}
          />
        )
      case 'file':
        return (
          <div key={field.name}>
            <Label className='label'>{field.label + ' ' + (field.required ? '*' : '')}</Label>
            <FileInput
              value={value}
              field={field.name}
              formData={props.formData}
              fileAcceptType={field.fileAcceptType}
              handleFileInputChange={(e) => props.handleFileInputChange(e)}
            />
          </div>
        )
      case 'crop':
        return (
          <ImageCropper></ImageCropper>
        )
      case 'multilineText':
        return (
          <TextField
            className='text-input'
            borderless
            key={field.name}
            label={props.hideLabel ? '' : (field.label || _.startCase(field.name) + ' ' + (field.required ? '*' : ''))}
            multiline
            rows={4}
            autoAdjustHeight
            placeholder={field.placeholder || field.label || field.name}
            type={field.type}
            value={field.type === 'date' ? Moment(value).format('YYYY-MM-DD') : value || ''}
            onChanged={(e) => props.handleInputChange(e)}
            autoComplete="off"
            errorMessage={props.hideValidationMessage ? '' : (props.validations && props.validations.error)}
          />
        )
      default:
        return (
          <TextField
            borderless
            className='text-input'
            key={field.name}
            label={props.hideLabel || field.hideLabel ? false : (field.label || _.startCase(field.name) + ' ' + (field.required ? '*' : ''))}
            type={field.type}
            placeholder={field.placeHolder || field.label || field.name}
            value={field.type === 'date' ? Moment(value).format('YYYY-MM-DD') : value || ''}
            onChanged={(e) => props.handleInputChange(e)}
            autoComplete={(field.type == "password") ? "new-password" : "off"}
            errorMessage={props.hideValidationMessage ? '' : (props.validations && props.validations.error)}
          />
          /* { this.state.validations[field.name] && this.state.validations[field.name].error &&
          <div style={styles.fieldMessage}>{this.state.validations[field.name].error}</div>
           } */
        )
    }
  }

  render() {
    return (
      this.getInputField(this.props)
    )
  }
}
export default FormInput
