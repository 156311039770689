const brand = {
  name: 'brand',
  url: '/brand',
  // formInNewPage: true,
  // viewInNewPage: true,
  manualCount:true,

  listActions: {
    // create: true,
    // edit: true,
    view: true,
    // filter: true
  },
  viewActions: {
    edit: true,
    remove: true
  },
  fields: {
    id: {
      type: 'number',
      hideInForm: true
    },
    name: {
      required: true,      
      hideInFilter: true
    },  
    description: {
      type: 'multilineText', 
      hideInFilter: true
    },  
    image_url: {
      type: 'file',
      label: 'image',
      hideInList: true,
      hideInFilter: true
    },
    featured: {
      type: 'boolean',
      label: 'Featured',
      // hideInForm: true,
      // hideInFilter:true,
      // hideInList: true,
    },
    // status: {
    //   type: 'boolean',
    //   hideInList:true,

    //   // hideInForm: true
    // }
  }
}

export default brand
