
import CategoryModel from '../category/category.model'

const product = {
  name: 'product',
  url: '/product',
  formInNewPage: true,
  viewInNewPage: true,
  manualCount:true,
  listActions: {
    create: true,
    edit: true,
    view: true,
    filter: true
  },
  listQuery: {
    'include[category]' : true
  },
  viewActions: {
    edit: true,
    remove: true
  },
  fields: {
    id: {
      type: 'number',
      hideInForm: true
    },
    name: {
      type: 'text',
      // hideInList: true,      
      // hideInFilter: true
    },
    short_description: {
      type: 'multilineText',
      // hideInList: true,      
      // hideInFilter: true
    },  
    description: {
      type: 'wysiwyg',
      hideInList: true,      
      hideInFilter: true
    },
    category_id: {
      label: 'Category',
      type: 'collection',
      collection: CategoryModel,
      required: true,
      // service: CategoryService
    },
    brand: {
      type: 'text',
      // hideInList: true,      
      // hideInFilter: true
    },
    image_url: {
      type: 'file',
      label: 'image',
      hideInList: true,
      hideInFilter: true
    },
    brochure_url: {
      type: 'file',
      fileAcceptType: 'application/pdf',
      label: 'Brochure',
      hideInList: true,
      hideInFilter: true
    },
    // featured: {
    //   type: 'boolean',
    //   hideInForm: true,
    //   hideInFilter:true,
    //   hideInList:true,
    // },
    // status: {
    //   type: 'boolean',
    //   hideInForm: true,
    //   hideInList:true,

    // }
  }
}

export default product
