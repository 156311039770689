import Api from '../../helpers/api'

const CollectionService = (URL, options) => {
  const handleError = (result) => {
    if(result.code === 'SERVER_ERROR') {
      if(options.showAlert) options.showAlert(result, 'error')      
      console.error(result)
    }
    if (result.message && result.message.errors && result.message.errors[0] && result.message.errors[0].message) {
      if(options.showAlert) options.showAlert(result.message.errors[0].message, 'error')      
      throw new Error({message: result.message.errors[0].message})
    } else if(result.message[0] && result.message[0].message) {
      if(options.showAlert) options.showAlert(result.message[0].message, 'error')      
      throw new Error({message: result.message[0].message})
    } else {
      if(options.showAlert) options.showAlert(result.message || result, 'error')      
      throw result
    }
  }

  const get = async (id) => {
    const result = await Api().get(URL+'/'+id)
    if (result.code === 200) {
      return result.data
    } else {
      handleError(result)
    }
  }

  const count = async (query) => {
    const result = await Api().get(URL+'/count', query)
    if (result.code === 'OK') {
      return result.data
    } else {
      handleError(result)
    }
  } 
  
  const list = async (query) => {
    const result = await Api().get(URL, query)
    if (result.code === 200) {
      return result.data.data
    } else {
      handleError(result)
    }
  }

  const create = async (data) => {
    const fileFlag = Object.keys(data).find((keyName) => {
      return data[keyName] instanceof File
    })
    let result 
    if (fileFlag) {
      result = await Api().postFile(URL, data)
    } else {
      result = await Api().post(URL, data)
    }
    if (result.code === 201) {
      if(options.showAlert) options.showAlert('Created Successfully', 'success')
      return result.data
    } else {
      return handleError(result)
    }
  }

  const update = async (id, data) => {
    const fileFlag = Object.keys(data).find((keyName) => {
      return data[keyName] instanceof File
    })
    let result 
    if (fileFlag) {
      result = await Api().putFile(URL+'/'+id, data)
    } else {
      result = await Api().put(URL+'/'+id, data)
    }
    if (result.code === 200) {
      if(options.showAlert) options.showAlert('Updated Successfully', 'success')      
      return result.data
    } else {
      handleError(result)
    }
  }

  const remove = async (id) => {
    const result = await Api().remove(URL+'/'+id)
    // if (result.code === 204) {
    if(options.showAlert) options.showAlert('Deleted Successfully', 'success')      
    return result.data
    // } else {
    //   handleError(result)
    // }
  }

  return {
    get,
    count,
    list,
    create,
    update,
    remove,
    handleError
  }
}

export default CollectionService