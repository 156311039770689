import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CollectionPage from './CollectionPage'
import CollectionService from './collection.service'

const propTypes = {
  model: PropTypes.object.isRequired,
  service: PropTypes.object,
  showAlert: PropTypes.func
}
const defaultPropTypes = {}
class CollectionPageBuilder extends Component {
  constructor(props){
    super(props)
  }
  render() {
    return (
      <CollectionPage 
        collectionName ={this.props.model.name}
        model={this.props.model}
        formFieldQuery={this.props.formFieldQuery}
        listQuery={this.props.listQuery}
        listHiddenFields={this.props.listHiddenFields}
        service={this.props.service?this.props.service:CollectionService(this.props.model.url, {showAlert: this.props.showAlert})}
        />    
    )
  }
}

CollectionPageBuilder.propTypes = propTypes

CollectionPageBuilder.defautlPropTypes = defaultPropTypes

export default CollectionPageBuilder