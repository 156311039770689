import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  ComboBox
} from 'office-ui-fabric-react/lib/ComboBox'

const propTypes = {
  options: PropTypes.array.isRequired,
  hideLabel: PropTypes.bool,
  field: PropTypes.object.isRequired,
  onChanged: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])
}

const defaultProps = {}

class SelectInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: [],
      selectedOptionKey: this.props.multiSelect ? [] : null,
      query: {},
      initial: true
    }
    this.onChanged = this.onChanged.bind(this)
  }

  async componentWillMount() {
    let options = this.convertResultToOptions(this.props.options)
    this.setState({options: options})
  }

  async componentWillReceiveProps(nextProps) {
    if (this.state.selectedOptionKey !== nextProps.value && this.state.initial) {
      await this.setState({selectedOptionKey: nextProps.value})
    }
  }
  onChanged(option){
    if(this.props.multiSelect){
      let selectedKeys = this.state.selectedOptionKey
      if (selectedKeys && option) {
        const index = selectedKeys.indexOf(option.key);
        if (option.selected && index < 0) {
          selectedKeys.push(option.key);
        } else {
          selectedKeys.splice(index, 1);
        }
      }
      this.setState({selectedOptionKey: selectedKeys, initial: false})
      this.props.onChanged(selectedKeys)
    }else{
      this.props.onChanged(option.key)    
    }
  }

  convertResultToOptions(data) {
    let options = []
    if (data && data.length) {
      if (typeof data[0] === 'string') {
        for (let i in data) {
          options.push({key:data[i], text: data[i]})
        }
      } else{
        options = data
      }
    }
    return options
  }

  render() {
    let { options, selectedOptionKey } = this.state
    return (
      <div>
        <ComboBox
          
          styles={{
            label:{
              color:'#666666 !important'
            },
            root:{
              border:'thin solid #ebebeb !important',
            }
          }}
          multiSelect={this.props.multiSelect ? true : false}
          selectedKey={ this.state.selectedOptionKey }
          label={ this.props.hideLabel?'':(this.props.field.label || _.startCase(this.props.field.name) +' '+ (this.props.field.required?'*':'')) }
          id={this.props.field.name}
          autoComplete='on'          
          allowFreeform={ false }
          options={ options }
          onResolveOptions={(e) => options }
          onChanged={this.onChanged}
          disabled={this.props.disabled || false}
        />
      </div>
    )
  }
}



SelectInput.propTypes = propTypes

SelectInput.defaultProps = defaultProps

export default SelectInput
