const convertObjectToArray = (object) => {
  let array = []
  Object.keys(object).map((keyName, keyIndex) => {
    array.push({...object[keyName], name: keyName})      
  })
  return array
}
const stringifyError = (err, filter, space) => {
  let plainObject = {};
  Object.getOwnPropertyNames(err).forEach( (key) => {
    plainObject[key] = err[key];
  });
  return JSON.stringify(plainObject, filter, space)  
}
const hasAccess = (rule) => {
  if(!rule) return true

  const Rules = {
    Employee: ['employee', 'admin'],
    Admin: ['admin']
  }
  const user = JSON.parse(localStorage.getItem('admin'))
  if(Rules[rule].includes(user.role)){
    return true
  }else{
    return false
  }
}

export {
  convertObjectToArray, stringifyError, hasAccess
}
