
const project = {
  name: 'project',
  url: '/project',
  // formInNewPage: true,
  // viewInNewPage: true,
  manualCount:true,
  listActions: {
    // create: true,
    // edit: true,
    view: true,
    filter: true
  },
  viewActions: {
    edit: true,
    remove: true
  },
  fields: {
    id: {
      type: 'number',
      hideInForm: true
    },
    name: {
      type:'text',
      required: true
    }
  }
}

export default project