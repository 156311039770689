import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { DefaultButton } from 'office-ui-fabric-react/lib/Button'
import { convertObjectToArray } from '../helpers/utils'
import FormInput from './FormInput'
import CollectionService from './collection/collection.service'

const propTypes = {
  model: PropTypes.object.isRequired,
  onFormChange: PropTypes.func,
  onClickAddButton: PropTypes.func,
  onClickUpdateButton: PropTypes.func,
  onClickCancelButton: PropTypes.func,
  onClickRemoveButton: PropTypes.func,
  initialData: PropTypes.object,
  formStyle: PropTypes.object,
  viewType: PropTypes.oneOf(['fullPage']),
  onEdit: PropTypes.bool,
  hideLabels: PropTypes.bool,
  hideValidationMessages: PropTypes.bool,
  filter: PropTypes.bool
}

const defaultProps = {}

class Form extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      fields: [],
      data: {},
      errors: {},
      validations: {},
      dynamicQuery:{}
    }
  }

  async componentWillMount() {
    this._isMounted = true
    await this.parseData()
  }

  async parseData(newModel) {
    let model = newModel || this.props.model
    let fields = convertObjectToArray(model.fields)
    fields = fields.filter(field => {
      if(this.props.filter){
        if(!field.hideInFilter ){
          return field          
        }
      }else if(field.initialHide){
        field.hide = true
        return field
      }else if(!field.hideInForm){
          return field
      }
    }) 
    if(this.props.onEdit) {
      fields = fields.filter(field => !field.hideInEdit)
    }
    if(this.props.initialData && this._isMounted) {
      await this.setState({data: this.props.initialData})
      _.forEach(fields, async(field) => {
        await this.handleDynamicValues(field.name, this.props.initialData[field.name])
      })
    } else if(this._isMounted) {
      let defaultData = this.getDefaults(fields, this.props.initialData)
      await this.setState({data: defaultData})
    }
    if(this.props.formFieldQuery){
      fields.filter(field => {
        let formFieldQuery = this.props.formFieldQuery
        if( formFieldQuery.field === field.name ){
          if(formFieldQuery.dynamicQuery){
            field.dynamicQuery = formFieldQuery.dynamicQuery
          }
          if(formFieldQuery.hideInForm){
            field.hide = formFieldQuery.hideInForm
          }
        }
      })
    }
    if(this._isMounted){
      await this.setState({fields: fields})
    }
  }
  getDefaults(fields, currentData={}) {
    _.each(fields, field => {
      if(field.default !== null) {
        if(currentData[field.name] === null || typeof currentData[field.name] === 'undefined') {
          currentData[field.name] = field.default
        }
      }
    })
    return currentData
  }

  async componentWillReceiveProps(nextProps) {
    if(nextProps.initialData !== this.props.initialData) {
      let fields = this.state.fields
      _.forEach(fields, async(field) => {
        await this.handleDynamicValues(field.name, nextProps.initialData[field.name])
      })
      await this.setState({data: nextProps.initialData, fields: fields})
      return
    }
    if(nextProps.model) {
      await this.parseData(nextProps.model)
    }
  }

  async validateForm(data, field=null) {
    let isValid = true
    await this.state.fields
    .filter(e => field? e.name === field : true)
    .forEach( async (field) => {
      if(field.name === 'decimals') {
        // console.log(data[field.name] , field.min, (data[field.name] < field.min))    
      }
      if(field.required && !data[field.name]) {
        isValid = false
        if(this.state.validations[field.name] && this.state.validations[field.name].dirty) {  
          await this.setState({ validations: { ...this.state.validations, [field.name]: { dirty: true, error: 'This field is required' } }})
        } 
      } else if(field.type === 'number' && (_.isNumber(field.max) && (data[field.name] > field.max))) {
        isValid = false
        if(this.state.validations[field.name] && this.state.validations[field.name].dirty) {  
          await this.setState({ validations: { ...this.state.validations, [field.name]: { dirty: true, error: `Cannot be more than ${field.max}` } }})
        }          
      } else if(field.type === 'number' && (_.isNumber(field.min) && (data[field.name] < field.min))) {
        isValid = false
        if(this.state.validations[field.name] && this.state.validations[field.name].dirty) {  
          await this.setState({ validations: { ...this.state.validations, [field.name]: { dirty: true, error: `Cannot be less than ${field.min}` } }})
        }          
      } else {
        if(this.state.validations[field.name] && this.state.validations[field.name].dirty) {  
          await this.setState({ validations: { ...this.state.validations, [field.name]: { dirty: true, error: null } }})
        }
      } 
    })
    return isValid
  }

  async handleDynamicValues(changedField, newValue) {
    await Promise.all(this.state.fields.map(async (field) => {
      if (field.dynamicValue && field.dynamicValue.onFieldsChange) {
        let index = field.dynamicValue.onFieldsChange.indexOf(changedField)
        if (index > -1) {
          if (field.dynamicValue.hideOnValueFromSiblings && field.dynamicValue.hideOnValueFromSiblings.length > 0) {
            let hideConditions = field.dynamicValue.hideOnValueFromSiblings
            hideConditions.forEach((line, index) => {
              if(line.nullValue){
                field.hide = [null, undefined, false, ''].indexOf(newValue) === -1 ? false : true
              }else if (changedField === line.formField && newValue === line.value) {
                field.hide = true
              }else{
                field.hide = false
              } 
            })
          }
          if (field.dynamicValue.getValueFromSiblings && field.dynamicValue.getValueFromSiblings.length > 0) {
            await Promise.all(field.dynamicValue.getValueFromSiblings
              .filter(model => model.formField === changedField)
              .map(async (model) => {
              let data = await CollectionService(model.collection.url).get(newValue)
              if (data) {
                let value = data[model.valueField]
                if (value) {
                  await this.handleInputChange(field.name, value)
                }
                return
              }
            }))
          }
          if (field.dynamicValue.computeFromSiblings) {
            if (field.dynamicValue.computeFromSiblings.operation === 'arraySum') {
              let result = 0
              let fieldName = field.dynamicValue.computeFromSiblings.field
              let filteredNewValue = newValue
                .filter(value => value.hasOwnProperty(fieldName))
                .map(value => { value[fieldName] = parseFloat(value[fieldName]); return value })
              result = _.sumBy(filteredNewValue, field.dynamicValue.computeFromSiblings.field)
              await this.handleInputChange(field.name, result || 0)
            } else if (field.dynamicValue.computeFromSiblings.operation === 'multiply') {
              let multiplyFields = field.dynamicValue.computeFromSiblings.fields
              let formData = this.state.data
              let result = null
              multiplyFields.forEach((fieldName, index) => {
                if (result === null) {
                  result = (fieldName === changedField)?newValue:formData[fieldName]
                } else {
                  result *= (fieldName === changedField)?newValue:formData[fieldName]
                }
              })
              await this.handleInputChange(field.name, result || 0)
            } else if (field.dynamicValue.computeFromSiblings.operation === 'add') {
              let addFields = field.dynamicValue.computeFromSiblings.fields
              let formData = this.state.data
              let result = null
              // let newValue = parseFloat(newValue) || 0
              addFields.forEach((fieldName, index) => {
                let negate = 1
                if (fieldName[0] === '-') {
                  negate = -1
                  fieldName = _.trimStart(fieldName, '-')
                }
                let formValue = parseFloat(formData[fieldName]) || 0
                if (result === null) {
                  result = ((fieldName === changedField)?newValue:formValue) * negate
                } else {
                  result += ((fieldName === changedField)?newValue:formValue) * negate
                }
              })
              await this.handleInputChange(field.name, result || 0)
            }
          }
        }
      }
    })) 
  }
  handleInputChangeWithDebounce =  _.debounce(async (field, value) => {
    if(!this._isMounted) return
    await this.handleInputChange(field, value)
  }, 500)

  handleInputChange = async (field, value) => {
    await this.handleDynamicValues(field, value)
    await this.setState({ data: { ...this.state.data, [field]: value || null } })
    await this.setState({validations: { ...this.state.validations, [field]: { ...this.state.validations[field], dirty: true}}})
    //handle dynamicValue, eg, get price in bill item line if item changes
    let isValid = await this.validateForm(this.state.data)
    await this.setState({isValid: isValid})
    // console.log("validation = ", this.state.validations)
    if (this.props.onFormChange) {
      this.props.onFormChange(this.state.data, isValid)      
    }
  }

  handleFileInputChange = async (field, value) => {
    await this.setState({ data: { ...this.state.data, [field]: value.target.files[0] || null } })
    if (this.props.onFormChange) {
      this.props.onFormChange(this.state.data, true)      
    }
  }

  handleCollectionInputChange = _.debounce(async (field, value) => {
    if(!this._isMounted) return
    if (value && value.key) {
      await this.handleInputChange(field, value.key)  
      value.id = value.key
      value.name = value.text
      await this.handleInputChange(_.replace(field,'_id',''), value)
    } else {
      await this.handleInputChange(field, value)  
    }
  }, 500)
  
  componentWillUnmount(){
    this._isMounted = false
  }

  render() {
    return (
      <div className="form-inputs">
        {
          this.state.fields.map((field, index) => {
            return (
              <React.Fragment key={index}>
              {
                !field.hide &&
                    <div className={field.size ? 'form-group '+field.size : 'form-group col-100'}>
                      <FormInput
                          onEdit={this.props.onEdit}
                          key={field.name}
                          selectedItem={this.props.selectedItem}
                          hideLabel={this.props.hideLabels}
                          hideValidationMessage={this.props.hideValidationMessages}
                          field={field}
                          value={this.state.data[field.name]}
                          handleInputChange={(value) => this.handleInputChangeWithDebounce(field.name, value)}
                          handleFileInputChange={(value) => this.handleFileInputChange(field.name, value)}
                          handleCollectionInputChange={(value) => this.handleCollectionInputChange(field.name, value)}
                          validations={this.state.validations[field.name]}
                          formData={this.state.data}
                          dynamicQuery={this.state.dynamicQuery}
                      />
                    </div>
              }
              </React.Fragment>
                  
            )
          })
        }
        {
          this.props.onClickAddButton &&
              <div className={'form-group'}>
                <DefaultButton
                    style={{width:'100%'}}
                    data-automation-id='add-line'
                    disabled={ !this.state.isValid }
                    checked={ true }
                    iconProps={ { iconName: 'Add' } }
                    title='Add'
                    ariaLabel='Add'
                    text='Add Line'
                    onClick={() => this.props.onClickAddButton(this.state.data, this.state.isValid)}
                />
              </div>
        }
        {
          this.props.onClickUpdateButton &&
          <div className={'form-group'}>
          <DefaultButton
              data-automation-id='add-line'
              disabled={ !this.state.isValid }
              checked={ true }
              title='Update'
              ariaLabel='Update'
              text='Update'
              onClick={() => this.props.onClickUpdateButton(this.state.data, this.state.isValid)}
          />
          </div>
        }
        {
          this.props.onClickRemoveButton &&
          <div className={'form-group'}>
          <DefaultButton
              data-automation-id='add-line'
              checked={ true }
              title='Remove'
              ariaLabel='Remove'
              text='Remove'
              onClick={() => this.props.onClickRemoveButton(this.state.data, this.state.isValid)}
          />
          </div>
        }
        {
          this.props.onClickCancelButton &&
          <div className={'form-group'}>
          <DefaultButton
              data-automation-id='add-line'
              checked={ true }
              title='Cancel'
              ariaLabel='Cancel'
              text='Cancel'
              onClick={() => this.props.onClickCancelButton(this.state.data, this.state.isValid)}
          />
          </div>
        }
      </div>
    )
  }
}

const styles = {
}

Form.propTypes = propTypes

Form.defaultProps = defaultProps

export default Form
