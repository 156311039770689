import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Label } from 'office-ui-fabric-react/lib/Label'
import _ from 'lodash'

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.any
  ]),
  field: PropTypes.object
}

let initialEditFlag = false

class Wysiwyg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialEditFlag: this.props.onEdit || false,
      value: ''
    }
  }

  debouncedOnChange = _.debounce(this.props.onChanged, 0)
  componentDidMount(){
    this.setState({initialEditFlag: this.props.onEdit || false})
  }

  handleChange = data => {
    const nextValue = data.data
    if(this.state.value !== nextValue){
      this.setState({value: nextValue})
      this.debouncedOnChange(nextValue);
    }
  }

  async componentWillReceiveProps(nextProps) {
    if(nextProps.value && this.state.initialEditFlag){
      await this.setState({initialEditFlag: false, value: nextProps.value})
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }
  
  render() {
    return (
      <div>
        <Label className='label'>{this.props.hideLabel?'':(this.props.field.label || _.startCase(this.props.field.name) +' '+ (this.props.field.required?'*':''))}</Label>
        <CKEditor
            editor={ ClassicEditor }
            data={this.state.value}
            onInit={ editor => {
                // console.log( 'Editor is ready to use!', editor );
            } }
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                this.handleChange({event, editor, data})
            } }
            className='ckeditor'
            onBlur={ editor => {
                // console.log( 'Blur.', editor );
            } }
            onFocus={ editor => {
                // console.log( 'Focus.', editor );
            } }
        />
      </div>
    )
  }
}

Wysiwyg.propTypes = propTypes

export default Wysiwyg
