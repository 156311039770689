import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  ComboBox
} from 'office-ui-fabric-react/lib/ComboBox'
import CollectionService from './collection/collection.service'

const propTypes = {
  field: PropTypes.object.isRequired,
  formData: PropTypes.object,
  value: PropTypes.number,
  hideLabel: PropTypes.bool,
  onChanged: PropTypes.func
}

const defaultProps = {}

class AutoCompleteInput extends Component {
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      options: [
      ],
      selectedOptionKey: null,
      value: null,
      getDataCalled: false,
      query: {}
    }
  }

  async componentWillMount() {
    this._isMounted = true;

    if (this.props.field.dynamicQuery) {
      if(_.isArray(this.props.field.dynamicQuery)){
        let query = {}  
        _.each(this.props.field.dynamicQuery, (q) => {
            let { field, value, staticValue, notNull } = q
            if(notNull){
              query[field] = '!null'
            }else{
              query[field] = (staticValue || staticValue === null) ? staticValue  : this.props.formData[value]               
            }
        })
        await this.setState({query: query})
      }else{
        let { field, value, staticValue } = this.props.field.dynamicQuery
        await this.setState({query: {[field]: (staticValue || staticValue === null) ? staticValue  : this.props.formData[value] }})
      }
    }
    setTimeout(async() => {
      if (!this.state.getDataCalled && this._isMounted) {
        await this.getData()  
        if (this.props.value) { 
          await this.setState({selectedOptionKey: this.props.value})
        }            
      }
    }, 100)
  }

  async componentWillReceiveProps(nextProps) {
    if (this.state.selectedOptionKey !== nextProps.value) {
      await this.setState({loading: true})
      await this.setState({loading: false, selectedOptionKey: nextProps.value})
    }
    if (nextProps.field.dynamicQuery) {
      if (nextProps.formData) {
        if(_.isArray(nextProps.field.dynamicQuery)){
          let query = {}  
          _.each(nextProps.field.dynamicQuery, (q) => {
              let { field, value, staticValue, notNull } = q
              if(notNull){
                query[field] = '!null'
              }else{
                query[field] = (staticValue || staticValue === null) ? staticValue  : nextProps.formData[value]               
              }
          })
          await this.setState({query: query})
        }else{
          let { field, value, staticValue } = nextProps.field.dynamicQuery
          await this.setState({query: {[field]: (staticValue || staticValue === null) ? staticValue  : this.props.formData[value] }})
        }
        await this.getData()
      }else{
        console.log('form data missing')
      }
    }
  }

  convertResultToOptions(model, data) {
    let keyField = 'id'
    let textField = 'name'
    let options = []
    // let options = [{[keyField]: null, [textField]: ''}]
    if (model.collectionInputConfig && model.collectionInputConfig.textField) {
      textField = model.collectionInputConfig.textField
    }
    // options.push({key:null, text: ''})
    for (let i in data) {
      options.push({ key: data[i][keyField], text: data[i][textField] || data[i]['title'] })
    }

    return options
  }

  async getData() {
    try {
      if(!this._isMounted) return
      this.setState({ loading: true, getDataCalled: true })
      if (this.props.field.collection) {
        const model = this.props.field.collection
        if (this.props.field.service) {
          const result = await this.props.field.service(model.url).list(this.state.query)
          if(this._isMounted){
            this.setState({ loading: false })
            let options = this.convertResultToOptions(model, result)
            await this.setState({ options: options })
          }
          return
        } else if (model.url) {
          const result = await CollectionService(model.url).list(this.state.query)
          if(this._isMounted){
            this.setState({ loading: false })
            let options = this.convertResultToOptions(model, result)
            await this.setState({ options: options })  
          }
          return
        }
        else {
          console.error("URL not found in model ", model)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let { options, selectedOptionKey, value } = this.state
    return (
      <div>
        {
          this.state.loading
            ? (
              <ComboBox
                label={this.props.hideLabel ? '' : (this.props.field.label || _.startCase(this.props.field.collection.name)) + ' ' + (this.props.field.required ? '*' : '')}

              />
            )
            : (
              <ComboBox
                selectedKey={ this.props.selectedItem ? this.props.selectedItem.id : selectedOptionKey && selectedOptionKey}
                label={this.props.hideLabel ? '' : (this.props.field.label || _.startCase(this.props.field.collection.name)) + ' ' + (this.props.field.required ? '*' : '')}
                id={this.props.field.name}
                disabled={this.props.selectedItem ? true : false}
                allowFreeform={false}
                styles={{
                  options:{
                    color:'yellow !important'
                  }
                }}
                autoComplete='on'
                options={options}
                onResolveOptions={(e) => this.state.options}
                onChanged={(e) => { this.props.onChanged(e) }}
              />
            )

        }

      </div>
    )
  }
}



AutoCompleteInput.propTypes = propTypes

AutoCompleteInput.defaultProps = defaultProps

export default AutoCompleteInput
