import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { IconButton, IButtonProps } from 'office-ui-fabric-react/lib/Button'
import Form from './Form'
import FormInput from './FormInput'
import { colors, metrics } from '../theme'

const propTypes = {}

const defaultProps = {}

class CollectionArrayInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: [
      ],
      value: [],
      lines: [],
      linesCount: 1,
      requiredFields: [],
      editLineIndex: null
    }
  }

  async componentWillMount() {
    this.getFieldsFromCollection(this.props.field.collection)
    if(this.props.value && this.props.value.length){
      this.props.value.map((item) => { item.isValid = true; return item } )
      await this.setState({value: this.props.value, lines: this.props.value || []})
    }
    if (this.props.field.multiLineForm) {
      this.addEmptyLine()  
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value) {
      if (nextProps.value) {
        let value = nextProps.value.map((item) => { item.isValid = true; return item } )
        await this.setState({value: nextProps.value, lines: nextProps.value || []})
        if (this.props.field.multiLineForm) {
          this.addEmptyLine()  
        }
      }
      
    }
  }

  getFieldsFromCollection( { fields }) {
    let result = []
    _.forEach(fields, (field, key) => {
      result.push({...field, name: key})
    })
    this.setState({fields: result})
  }

  async addEmptyLine() {
    let lines = this.state.lines
    let newLine = { isValid: false }
    lines.push(newLine)
    await this.setState({lines: lines})
    
  }

  updateParentForm() {
    this.props.onChanged(this.state.value)
  }

  async addLine(line, isValid) {
    let lines = this.state.lines
    line.isValid = isValid
    lines.push(line)
    await this.setState({initialData: {}, lines: lines, value: lines.filter(line => line.isValid)})
    this.props.onChanged(this.state.value)
  }

  async removeLine(index) {
    let lines = this.state.lines
    lines.splice(index, 1)
    await this.setState({lines: lines, value: lines.filter(line => line.isValid), editLineIndex: null})
    this.props.onChanged(this.state.value)      
  }

  async updateLine(index, data) {
    let lines = this.state.lines
    lines[index] = data
    await this.setState({lines: lines, value: lines.filter(line => line.isValid)})
    this.props.onChanged(this.state.value)
    this.hideEditForm()
  }

  hideEditForm() {
    this.setState({editLineIndex: null})
  }

  showEditForm(index) {
    console.log("show edit form ", index)
    this.setState({editLineIndex: index})
  }

  async onFormChange(index, data, isValid) {
    let lines = this.state.lines
    lines[index] = { ...data, isValid: isValid }
    await this.setState({lines: lines, value: lines.filter(line => line.isValid)})
    if (this.props.field.multiLineForm) {
      this.props.onChanged(this.state.value)      
      if (lines.length === index+1) {
        this.addEmptyLine()
      }
    }
  }

  render() {
    const table = (
      <div className={'collection-array-input'}>
        {
          this.props.field.multiLineForm &&
          (
            <div>
              <div style={styles.row}>
                {
                  this.state.fields
                  .filter(field => !field.hideInForm)
                  .map(field => 
                    (
                      <div style={styles.column} key={field.name}>{field.label || _.startCase(field.name)}</div>
                    )
                  )
                }
              </div>
              <div>
                {
                  this.state.lines.map((line, index) => 
                  (
                    <Form 
                      key={index} 
                      formStyle={line.isValid?styles.validRow:styles.row} 
                      formGroupStyle={styles.column} 
                      model={this.props.field.collection} 
                      initialData={line}
                      hideLabels={true}
                      hideValidationMessages={true}
                      onFormChange={(data, isValid) => this.onFormChange(index, data, isValid)}
                      onClickAddButton={false}
                      
                    />
                  )) 
                }
              </div>
            </div>
          )
        }
        
        <div className={'collection-a-i-lines'}>
          {
            !this.props.field.multiLineForm &&
            <div>
              {
                this.state.lines.length > 0 &&
                <p style={{color:'#4badd1', margin:'0 0 5px'}}>*Click on lines to edit or Delete</p>
              }
              {
                this.state.lines.length > 0 &&
                <table width='100%' style={{margin:'0 7px'}}>
                  {
                    !this.props.field.hideTableLabel &&
                    <thead>
                    <tr>
                      {
                        this.props.field.tableViewFields.map(field =>
                            <td key={field.name}>{field.label}</td>
                        )
                      }
                    </tr>
                    </thead>
                  }
                  <tbody>
                    {
                      this.state.lines &&
                      this.state.lines.map((line,index)  => 
                        <tr key={index} onClick={() => this.showEditForm(index)} style={{cursor:'pointer'}}>
                          {
                            this.props.field.tableViewFields.map(field => 
                              <td key={field.name}>{field.subField?(line[field.name][field.subField]):line[field.name]}</td>         
                            )
                          }
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              }
              {
                this.state.editLineIndex !== null &&
                <Form 
                  model={this.props.field.collection} 
                  initialData={this.state.lines[this.state.editLineIndex]}
                  onClickUpdateButton={(data, isValid) => this.updateLine(this.state.editLineIndex, data)}
                  onClickRemoveButton={(data, isValid) => this.removeLine(this.state.editLineIndex)}
                  onClickCancelButton={(data, isValid) => this.hideEditForm()}
                  />
              }
              {
                this.state.editLineIndex === null &&
                <Form 
                  model={this.props.field.collection} 
                  initialData={this.state.initialData}
                  onClickAddButton={(data, isValid) => this.addLine(data, isValid)}
                  />

              }
              
            </div>
            
          }                  
        </div>
      </div>
    )
    return (
      <div style={styles.container}>
        {
          !this.props.field.hideLabel &&
          <div style={styles.header}>
            {this.props.field.label}
          </div>
        }
        {
          this.props.field.inputType === 'table' &&
          table
        }
      </div>
    )
  }
}

const styles = {
  container: {
    backgroundColor: '#f3f3f3',
    padding: 8,
    marginBottom: 8
  },
  header: {
    fontWeight: 'bold',
    color: colors.lightPrimary,
    fontSize:'.9em'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  validRow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: `${colors.primary} thin solid`
  },
  column: {
    flex: 1,
    minWidth:100,
  }

}

CollectionArrayInput.propTypes = propTypes

CollectionArrayInput.defaultProps = defaultProps

export default CollectionArrayInput
