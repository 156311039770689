import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TagPicker } from 'office-ui-fabric-react/lib/components/pickers/TagPicker/TagPicker'
import { Label } from 'office-ui-fabric-react/lib/Label'
import CollectionService from './collection/collection.service'
import _ from 'lodash'

const propTypes = {}

const defaultProps = {}

class TagInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      options: [
      ],
      value: null,
      selectedItems: [],
      editFlag: true,
      query: {}
    }
  }

  async componentWillMount() {
    if (this.props.field.dynamicQuery) {
      let { field, value, staticValue } = this.props.field.dynamicQuery
      let query = {[field]: (staticValue || staticValue === null) ? staticValue  : this.props.formData[value] }
      await this.setState({query:  _.pickBy(query, v => v !== undefined)})
    }
    await this.getData()
  }

  async componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value) {
      await this.setState({value: nextProps.value})
      if(this.state.editFlag && nextProps.value && nextProps.value.length){
        let selectedItems = []
        if(this.props.field.field){
          selectedItems = nextProps.value.map((item) => {
            return {key: item[this.props.field.field], name: item[_.replace(this.props.field.field,'_id','')].name} 
          })
        }else{
          selectedItems = nextProps.value.map((item) =>{
            return  _.find(this.state.options, {key: item}) || null
          })
        }
        await this.setState({selectedItems: _.compact(selectedItems)})
      }else if(!nextProps.value){
        await this.setState({selectedItems: []})
      }
    }
    if (this.props.field.dynamicQuery) {
      if (this.props.formData) {
        let { field, value, staticValue } = this.props.field.dynamicQuery
        if (this.state.query[field] !== nextProps.formData[value]) {
          let query = {[field]: (staticValue || staticValue === null) ? staticValue  : nextProps.formData[value] }
          await this.setState({query: _.pickBy(query, v => v !== undefined)})
          await this.getData()
        }
      } else {
        console.warn("Form data missing in props")
      }
    }
  }

  convertResultToOptions(model, data) {
    let options = []
    let keyField = 'id'
    let textField = 'name'
    if (model.collectionInputConfig && model.collectionInputConfig.textField) {
      textField = model.collectionInputConfig.textField
    }
    if(this.props.field.textField){
      textField = this.props.field.textField
    }
    for (let i in data) {
      options.push({key: data[i][keyField], name: data[i][textField]})
    }
    return options
  }

  async getData() {
    try {
      this.setState({loading: true})
      if (this.props.field.collection) {
        const model = this.props.field.collection
        if (this.props.field.service) {
          const result = await this.props.field.service(model.url).list(this.state.query)
          let options = this.convertResultToOptions(model, result)
          await this.setState({options: options, loading: false})
          return
        } else if (model.url) {
          const result = await CollectionService(model.url).list(this.state.query)
          let options = this.convertResultToOptions(model, result)
          await this.setState({options: options, loading: false})
          return
        }
        else {
          console.error("URL not found in model ", model)
        }
      }
    } catch (error) {
      console.error(error)
    }    
  }


  _onDisabledButtonClick() {
    this.setState({
      isPickerDisabled: !this.state.isPickerDisabled
    });
  }

  _onFilterChanged(filterText, tagList) {
    return filterText ? this.state.options.filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0).filter(item => !this._listContainsDocument(item, tagList)) : [];
  }

  _listContainsDocument(tag, tagList) {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.filter(compareTag => compareTag.key === tag.key).length > 0;
  }

  _returnMostRecentlyUsed(currentPersonas) {
    let mostRecentlyUsed = this._removeDuplicates(this.state.options, currentPersonas);
    mostRecentlyUsed = mostRecentlyUsed.splice(0, 8);
    return mostRecentlyUsed
  }
  _removeDuplicates(personas, possibleDupes) {
    let allIds = _.map(personas, 'key')
    let dupIds = _.map(possibleDupes, 'key')
    let diff = _.difference(allIds, dupIds)
    return personas.filter(persona => diff.indexOf(persona.key) !== -1);
  }


  async handleChange(list) {
    await this.setState({editFlag: false, selectedItems: list})
    if (this.props.field.field) {
      let result = []
      for (let i in list) {
        result.push({[this.props.field.field]: list[i].key})
      }
      this.props.onChanged(result)  
    } else {
      this.props.onChanged(list.map(item => item.key))
    }
  }

  render() {
    return (
      <div>
        <Label>{ this.props.field.label }</Label>
        <TagPicker ref='tagPicker'
          onResolveSuggestions={ this._onFilterChanged.bind(this) }
          getTextFromItem={ (item) => { return item.name; } }
          selectedItems={this.state.selectedItems}
          onEmptyInputFocus={ this._returnMostRecentlyUsed.bind(this) }
          pickerSuggestionsProps={
            {
              suggestionsHeaderText: 'Suggested Tags',
              noResultsFoundText: 'No Match Found'
            }
          }
          disabled={ this.state.isPickerDisabled }
          onChange={ (list) => this.handleChange(list)}
        />
      </div>
    )
  }
}

TagInput.propTypes = propTypes

TagInput.defaultProps = defaultProps

export default TagInput
