import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DefaultButton } from 'office-ui-fabric-react/lib/Button'
import './TopNav.scss'

const propTypes = {
  onLogout: PropTypes.func.isRequired,
}

const defaultProps = {}

class TopNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
    };
  }
  logOut() {
    this.props.onLogout();
  }
  onMenuClick(){
    console.log('log')
  }
  // _getMenu(){
  //   // Customize contextual menu with menuAs
  //   return <ContextualMenu {...menuProps} />;
  // };
  render() {
    const { disabled, checked } = this.props;
    const { beakWidth, directionalHint, directionalHintForRTL, edgeFixed, gapSpace, isBeakVisible, useDirectionalHintForRtl } = this.state;
    return (
      <div className="TopNav">
        <div className='nav-title'>

        </div>
        <div className='user'>
          <p>Username</p>
          <DefaultButton
            iconProps={ { iconName: 'contact' } }
            // onMenuClick={()=>this.onMenuClick()}
            style={{fontSize: '10px',padding:0,fontWeight: 'bold',borderRadius:'50%',  minWidth: '32px'}}
            menuProps={{
              shouldFocusOnMount: true,
              items: [
                {
                  key: 'Profile',
                  text: 'Profile',
                  name:'Profile',
                  // componentRef:'/profile',
                  href:'#/profile',
                  iconProps: {
                    iconName: 'contact'
                  },
                },
                {
                  key: 'Logout',
                  text: 'Logout',
                  name:'Logout',
                  onClick:()=>this.logOut(),
                  iconProps: {
                    iconName: 'SignOut'
                  },
                },
              ]
            }}
          />
        </div>
      </div>
    )
  }  
  
}

TopNav.propTypes = propTypes

TopNav.defaultProps = defaultProps

export default TopNav
