import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField } from 'office-ui-fabric-react/lib/TextField'
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button'
import { MessageBar } from 'office-ui-fabric-react/lib/MessageBar'
import AuthService from './authService'
import { colors } from '../theme'
import logo from '../logo.svg'
const propTypes = {
  onLoginSuccess: PropTypes.func.isRequired
}

const defaultProps = {}

class LoginForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      loading: false,
      error: null
    }
  }

  validateForm() {
    if(this.state.email && this.state.password) {
      return true
    } else {
      return false
    }
  }

  async tryLogin(e) {
    e.preventDefault()
    try {
      if(this.validateForm()){
        const result = await AuthService().login(this.state.email, this.state.password)
        if (result.token) {
          this.props.onLoginSuccess()
        } else {
          const message = result.message || 'Login Failed'
          this.setState({error: message})  
        }
      }else {
        this.setState({error: 'Please provide both email and password'})
      }
      
    } catch (error) {
      this.setState({error: 'Something went wrong'})
    }
  }

  handleInputChange(field, value) {
    this.setState({[field]: value})
  }

  render() {
    return (
      <div>
        <form onSubmit={(e)=>this.tryLogin(e)}  noValidate style={styles.container}>
            <TextField label='Username' className='text-input' borderless value={this.state.email} onChanged={(e) => this.handleInputChange('email', e) } />
            <TextField label='Password' className='text-input' borderless type='password' value={this.state.password} onChanged={(e) => this.handleInputChange('password', e) } />
            <PrimaryButton style={styles.loginButton} type="submit" text="Login"/>
            {
              this.state.error  &&
              <MessageBar>{this.state.error}</MessageBar>
            }
        </form>
        
      </div>
    )
  }
}

const styles = {
  container : {
    minWidth:'400px',
    padding:26,
    border: '#C7C7C7 thin solid',
    backgroundColor: 'white',
    boxShadow:'0px 0px 10px 0px #c7c7c7'
  },
  header : {
    // backgroundColor: '#34495E',
    // backgroundColor: '#3f51b5',
    backgroundColor: colors.primary,
    width: '100%',
    padding : '3em 4em',
    textAlign : 'center'
  },
  form : {
    padding: 26
  },
  loginButton : {
    marginTop: 20,
    width: '100%'
  }
}

LoginForm.propTypes = propTypes

LoginForm.defaultProps = defaultProps

export default LoginForm
