import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom' 
import NotFoundPage from '../../components/NotFoundPage'
import CollectionPageBuilder from './collectionPageBuilder'
import CollectionService from './collection.service'
import CollectionNew from './CollectionNew'
import CollectionView from './CollectionView'
import CollectionEdit from './CollectionEdit'

const propTypes = {
  model: PropTypes.object.isRequired,
  baseRoute: PropTypes.string.isRequired
}

const defaultProps = {}

class CollectionRouterBuilder extends React.Component {

  render() {
    return (
      <Switch>
        {/* <Route exact path={'/services/new'} component={() =>
          <CollectionNew viewType={"fullPage"} collectionName={this.props.model.name} model={this.props.model} service={CollectionService(this.props.model.url, {showAlert: this.props.showAlert})} />
        }/>
        <Route exact path={'/services/:id/edit'} component={() =>
          <CollectionEdit viewType={"fullPage"} collectionName={this.props.model.name} model={this.props.model} service={CollectionService(this.props.model.url, {showAlert: this.props.showAlert})} />
        }/> 
        <Route path={'/services/:id'} component={() =>
          <CollectionView showAlert={(message, type)=>this.props.showAlert(message, type)} model={this.props.model} service={CollectionService(this.props.model.url, {showAlert: this.props.showAlert})}/>
        }/>
          <Route exact path={'/sectors/new'} component={() =>
            <CollectionNew viewType={"fullPage"} collectionName={this.props.model.name} model={this.props.model} service={CollectionService(this.props.model.url, {showAlert: this.props.showAlert})} />
          }/>
        <Route exact path={'/sectors/:id/edit'} component={() =>
          <CollectionEdit viewType={"fullPage"} collectionName={this.props.model.name} model={this.props.model} service={CollectionService(this.props.model.url, {showAlert: this.props.showAlert})} />
        }/> 
        <Route path={'/sectors/:id'} component={() =>
          <CollectionView showAlert={(message, type)=>this.props.showAlert(message, type)} model={this.props.model} service={CollectionService(this.props.model.url, {showAlert: this.props.showAlert})}/>
        }/>  */}
        
        <Route exact path={`/${this.props.baseRoute}/new`} component={() => 
          <CollectionNew viewType={"fullPage"} collectionName={this.props.model.name} model={this.props.model} service={CollectionService(this.props.model.url, {showAlert: this.props.showAlert})} />
        }/>
        <Route exact path={`/${this.props.baseRoute}/:id`} component={() => 
          <CollectionView viewType={"fullPage"} collectionName={this.props.model.name} model={this.props.model} service={CollectionService(this.props.model.url, {showAlert: this.props.showAlert})} />
        }/>
        <Route exact path={`/${this.props.baseRoute}/:id/edit`} component={() =>
          <CollectionEdit viewType={"fullPage"} collectionName={this.props.model.name} model={this.props.model} service={CollectionService(this.props.model.url, {showAlert: this.props.showAlert})}/>
        }/>
        <Route exact path={`/${this.props.baseRoute}/`} component={() => <CollectionPageBuilder model={this.props.model} showAlert={this.props.showAlert} listQuery={this.props.model.listQuery}/>} />
        
        <Route component={NotFoundPage} />
      </Switch>    
    )
  }
}
CollectionRouterBuilder.propTypes = propTypes

CollectionRouterBuilder.defaultProps = defaultProps

export default CollectionRouterBuilder