
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { colors } from '../../theme'
import { Slider } from 'office-ui-fabric-react/lib/Slider'
import { DefaultButton, IconButton } from 'office-ui-fabric-react/lib/Button'
import { withRouter } from 'react-router-dom'
import { 
  DetailsListLayoutMode,
  Selection
} from 'office-ui-fabric-react/lib/DetailsList'
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection'
import { convertObjectToArray, hasAccess } from '../../helpers/utils'
import moment from 'moment'
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel'
import {CSVDownload} from 'react-csv'
import Form from '../Form'
import SelectInput from './../SelectInput'
import { Label } from 'office-ui-fabric-react/lib/Label';

const propTypes = {
  model: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
  newData: PropTypes.object,
  updatedData: PropTypes.object,
  removedData: PropTypes.number,
  onItemClick: PropTypes.func,
  hideHeader: PropTypes.bool,
  query: PropTypes.object
}

const defaultProps = {}

class CollectionList extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      data: [],
      sortField: 'id',
      sortOrder: 'desc',
      error: null,
      page: 1,
      dataCount: null,
      pageCount: null,
      limit: 100,
      selectionDetails: [],
      columns: [],
      query: {},
      showPanel: false,
      filterQuery: {}      
    }
  }

  async componentDidMount() {
    this._isMounted = true
    await this.getFields()
    await this.getCount()
    await this.getData()
  }

  async getFields() {
    let fields = convertObjectToArray(this.props.model.fields)
    if(this.props.listHiddenFields){
      fields.filter(field => {
        if(_.findIndex(this.props.listHiddenFields, (e) => e === field.name) !== -1){
          field.hideInList = true
        }
      })
    }
    fields = fields.filter(field => !field.hideInList)
    let columns = this.convertToColumns(fields)
    if(this._isMounted){
      this.setState({columns: columns})
    }
  }

  async getCount(query) {
    try {
      if(this.props.query){
        query = {...this.props.query, query}
      }
      if(this.props.model.manualCount && this._isMounted){
        this.setState({dataCount: 0, pageCount: 10})
        return
      }
      const result = await this.props.service.count(query)
      if (result && this._isMounted) {
        let pageCount = _.ceil(result / this.state.limit)
        this.setState({dataCount: result, pageCount: pageCount})
      } else if(this._isMounted) {
        this.setState({dataCount: 0, pageCount: 0})  
      }
    } catch (error) {
      console.error(error)
    }
  }
  convertBooleanAndCollectionToValue(data) {
    let fields = _.filter(this.state.columns, (e)=> {
      return e.type === 'boolean' || e.type === 'collection' || e.type === 'date'
    })
    data.map((item) => {
      fields.map((field) => {
        if(field.type === 'boolean'){
          if(typeof item[field.fieldName] === 'string'){
            item[field.fieldName] = parseInt(item[field.fieldName]) ? "Yes" : "No"
          }else{
            item[field.fieldName] = item[field.fieldName] ? "Yes" : "No"
          }
          // // // item[field.fieldName] = item[field.fieldName] ? "Yes" : "No"          
        }else if(field.type === 'collection'){
          let newField = field.fieldName.replace('_id', '')
          if(item[newField]){
            item[field.fieldName] = item[newField].name || item[newField].title
          }
        }else if(field.type === 'date'){
          item[field.fieldName] = item[field.fieldName] ? moment(item[field.fieldName]).format("Do MMM YY") : ""
        }
      })
    })
    return data
  }
  async getData(query) {
    try {
      if(this._isMounted){
        this.setState({loading: true})
      }
      let sortString = this.getSortString(this.state.sortField, this.state.sortOrder)
      query = query && Object.keys(query).length ? query : {}
      if(this.props.query){
          query = {...this.props.query, ...query, sort: sortString, page: this.state.page, limit: this.state.limit}
      }else {
        query = {...query, sort: sortString, page: this.state.page, limit: this.state.limit}
      }
      let result = await this.props.service.list(query)
      result = this.convertBooleanAndCollectionToValue(result)
      if(this._isMounted){
        if(this.props.model.manualCount){
          this.setState({dataCount: result.length, pageCount: 1})
        }
        this.setState({loading: false, data: result})
      }
    } catch (error) {
      this.setState({loading: false, error: error.message || 'Something went wrong'})
    }
  }

  getSortString(field, order) {
    if (order === 'desc') {
      return '-'+field
    }
    return field
  }

  convertToColumns(fields) {  
    let columns = []
    _.forEach(fields, field => {
      let defaultProps = {}
      if(!field.listProps) {
        if (field.name === 'id') {
          defaultProps = {
            minWidth: 20,
            maxWidth: 40,
          }
        }if (field.name === 'userId' || field.name === 'paid' || field.name === 'amount') {
          defaultProps = {
            minWidth: 40,
            maxWidth: 40,
          }
        } else if (field.name === 'name') {
          defaultProps = {
            // maxWidth: 130,
            isRowHeader: true,
            isResizable: true
          }
        } else if (field.name === 'subject') {
          defaultProps = {
            minWidth: 300,
            isRowHeader: true,
            isResizable: true
          }
        } else if (field.name === 'email') {
          defaultProps = {
            isResizable: true
          }
        }
      }
      columns.push({
        ...defaultProps,
        ...field,
        ...field.listProps,
        key: field.name,
        fieldName: field.name,
        name: field.label?field.label:_.startCase(field.name),
        data: field.type,
        onColumnClick: (e) => this._onColumnClick(field.name)      
      })
    })
    return columns
  }

  async componentWillReceiveProps(nextProps) {
    if(nextProps.query !== this.props.query){
      this.getData()
    }
    if(nextProps.getNewData){
      this.getCount()
      this.getData()
    }
    if(nextProps.newData !== this.props.newData) {
      let data = this.state.data
      await this.setState({data:[]})
      await this.setState({data:[nextProps.newData, ...data]}) 
    }
    if(nextProps.updatedData && (nextProps.updatedData !== this.props.updatedData)) {
      let data = this.state.data
      let newData = []
      for(let i in data) {
        if(data[i].id === nextProps.updatedData.id) {
          newData.push(nextProps.updatedData)
        }else {
          newData.push(data[i])
        }
      }
      await this.setState({data:newData})
    }
    if(nextProps.removedData  && (nextProps.removedData !== this.props.removedData)) {
      let data = this.state.data
      _.remove(data, (item) => item.id === nextProps.removedData)
      await this.setState({data:[]})
      await this.setState({data:data})
    }
    let newData = this.getCollectionNames(this.state.data)
    await this.setState({data: newData})
    return nextProps 
  }

  getCollectionNames(data) {
    let collectionFields = this.state.columns.filter((column) => column.type === 'collection')
    data.map(item => {
      _.forEach(collectionFields, (field) => {
        if(field['collection'] && item[field['collection']['name']]) {
          let textField = 'name'
          if(field['collection']['collectionInputConfig']) {
            textField = field['collection']['collectionInputConfig']['textField']
          }
          item[field.key] = item[field['collection']['name']][textField]
        } else {
          console.warn('collection properties not found or data not present')
        }
      })
      return item
    })
    let collectionArrayFields = this.state.columns.filter((column) => column.type === 'collectionArray') 
    data.map(item => {
      _.forEach(collectionArrayFields, (field) => {
        item[field.key] = '//'
      })
      return item
    })
    return data
  }

  async _onColumnClick(field) {
    let sortOrder = this.state.sortOrder
    if(this.state.sortField === field) {
      if(sortOrder === 'asc') {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }
    }
    await this.setState({sortField: field, sortOrder: sortOrder})
    this.getData()    
    // this.props.onSortChange({field: field, order: sortOrder})
    let newColumns = []
    _.forEach(this.state.columns, (column) => {
      let newColumn = column
      if (column.key === this.state.sortField) {
        if (this.state.sortOrder === 'asc') {
          newColumn.isSorted = true
          newColumn.isSortedDescending = false
        } else {
          newColumn.isSorted = true
          newColumn.isSortedDescending = true
        }      
      }
      newColumns.push(newColumn)
    })
    await this.setState({columns: newColumns})
    return
  }

  onItemClick(id, item) {
    if(this.props.onItemClick) {
      this.props.onItemClick(id)
    }else 
    if(this.props.onItemClickWithData){
      this.props.onItemClickWithData(id, item)
    }
  }

  onRowSelected = async (selected) => {
    console.log('on Row selected', selected)  
  }

  showNew = async () => {
      this.props.history.push(this.props.location.pathname + '/new')
  }

  showEdit = async () => {
    this.props.history.push(this.props.location.pathname + '/' + this.state.selectionDetails[0] + '/edit')
  }
  showView = async () => {
    this. props.history.push(this.props.location.pathname + '/' + this.state.selectionDetails[0])
  }
  
  showFilter = () => {
    this.setState({showPanel: true})
  }
  onFilterDataChange(data, isValid) {
    this.setState({filterQuery: data, isValid: isValid})
  }
  filterData = async () => {
    let newFilterQuery = {}
    Object.keys(this.state.filterQuery).map((key) => {
      let field = this.props.model.fields[key]
      if(!field) return 
      switch(field.type){
        case 'boolean': 
          newFilterQuery[key] = this.state.filterQuery[key] === null ? false : true
          break
        case 'date':
        case 'select': 
        case 'email':
        case 'number':
        case 'time':
        case 'file':
        case 'collection': 
          newFilterQuery[key] = this.state.filterQuery[key]
          break
        default: 
          newFilterQuery[key] = this.state.filterQuery[key] ? '%' + this.state.filterQuery[key] + '%' : null
          break
      }
    })
    await this.setState({showPanel: false, filter: true, newFilterQuery: newFilterQuery, page: 1})
    await this.getCount(newFilterQuery)
    await this.getData(newFilterQuery)
  }
  clearFilterData() {
    this.getCount()
    this.setState({filterQuery: {}, newFilterQuery: {}, filter: false, showPanel: false, page: 1})
    this.getData()
  }
  async setPage(page) {
    if (page > 0 && page <= this.state.pageCount) {
      await this.setState({page: page})
      await this.getData(this.state.filterQuery)  
    }
    return
  }
  async setLimit(limit) {
    await this.setState({limit: limit})
    let pageCount = _.ceil(this.state.dataCount / this.state.limit)
    await this.setState({pageCount: pageCount})
    await this.getData(this.state.newFilterQuery)  
    return
  }
  async exportCSV() {
    this.setState({exportFlag: true})
    setTimeout(()=> {
      this.setState({exportFlag: false})
    }, 1000)
  }

  _selection = new Selection({
    onSelectionChanged: async() => {
      const selected = this._getSelectionDetails()
      if(this.props.onItemSelected){
        this.props.onItemSelected(selected)
      }
      await this.setState({ selectionDetails: selected })
    }    
  })

  _getSelectionDetails() {
    return _.map(this._selection.getSelection(), 'id')
  }
  async doAction(action){
    await this.props.service[action.action](this.state.selectionDetails, this)
    this.getData()
  }

  componentWillUnmount(){
    this._isMounted = false
  }

  render() {
    return (
      <div id="CollectionList" className="flex flex-1 column">
        { !this.props.hideHeader &&
          <div className="header">
            <div className="title">
              { _.startCase(this.props.model.name) }
            </div>
            <div className="actions">
              {
                this.props.model.listActions && this.props.model.listActions.filter &&
                <DefaultButton
                    text='FILTER'
                    iconProps={ { iconName: 'filter' } }
                    onClick={() => this.showFilter()}
                    style={this.state.filter ? {color: 'black'}  : {}}
                />
              }
              {
                this.props.model.listActions && this.props.model.listActions.create &&
                <DefaultButton
                    text='NEW'
                    iconProps={ { iconName: 'Add' } }
                    onClick={() => this.showNew()}
                />
              }
              {
                this.state.selectionDetails.length === 1 && this.props.model.listActions && this.props.model.listActions.edit &&
                <DefaultButton
                    text='Edit'
                    iconProps={ { iconName: 'Edit' } }
                    onClick={() => this.showEdit(this.props.match.params.id)}
                />
              }
              {
                this.state.selectionDetails.length === 1 && this.props.model.listActions && this.props.model.listActions.view &&
                <DefaultButton
                    text='View'
                    iconProps={ { iconName: 'View' } }
                    onClick={() => this.showView(this.props.match.params.id)}
                />
              }
              {
                <DefaultButton
                    text='Export'
                    iconProps={ { iconName: 'View' } }
                    onClick={()=> this.exportCSV()}
                />
              }
              {
                this.state.exportFlag &&
                <CSVDownload data={this.state.data} target="_blank" />
              }
              {this.props.model.listActions && this.props.model.listActions.otherActions && this.props.model.listActions.otherActions.map((action, index) => {
                return (
                  <Fragment key={index}>
                    { !action.multi && this.state.selectionDetails.length === 1 && hasAccess(action.authorizeRule) &&
                        <DefaultButton
                          text={action.name}
                          iconProps={ { iconName: action.icon } }
                          onClick={()=> this.doAction(action)}
                        />
                    }
                    {
                      action.multi && this.state.selectionDetails.length >= 0 && hasAccess(action.authorizeRule) &&
                        <DefaultButton
                          text={action.name}
                          iconProps={ { iconName: action.icon } }
                          onClick={()=> this.doAction(action)}
                        />
                    }
                  </Fragment>
                )
              })}

            </div>
          </div>
        }
        <div className="body-container flex column">
          <div className="flex-1 y-scroll">
            <MarqueeSelection selection={ this._selection }>
              {
                <ShimmeredDetailsList
                    items={ this.state.data }
                    className='detailed-list'
                    columns={ this.state.columns }
                    layoutMode={ DetailsListLayoutMode.justified }
                    enableShimmer={this.state.loading}
                    isHeaderVisible={ true }
                    onItemInvoked={ (item) => this.onItemClick(item.id, item) }
                    selection={ this._selection }
                />
              }
            </MarqueeSelection>
          </div>
          <div style={styles.paginationContainer} className="pagination">
            <Label style={styles.limitInput}>Total: {this.state.dataCount}</Label>
            {!this.props.model.manualCount &&
            <div className="limitInput" style={styles.limitInput}>
              <SelectInput
                  key="key"
                  field={{name: 'limit'}}
                  value={this.state.limit.toString()}
                  options={['50','100', '200', '500']}
                  hideLabel={true}
                  onChanged={(value) => this.setLimit(value)}
              />
            </div>
            }
            <div style={styles.paginationButtons}>
              <IconButton
                  iconProps={ { iconName: 'chevronLeft' } }
                  title='Previous'
                  ariaLabel='Previous'
                  onClick={() => this.setPage(this.state.page - 1)}
              />
              <div style={styles.spinButton}>
                <input
                    type="number"
                    value={this.state.page}
                    style={styles.paginationInput}
                    onChange={(e,a) => this.setPage(e.target.value)}
                />
              </div>
              <IconButton
                  iconProps={ { iconName: 'chevronRight' } }
                  title='Next'
                  ariaLabel='Next'
                  onClick={() => this.setPage(this.state.page + 1)}
              />
            </div>
            <div style={styles.paginationSlider}>
              <div style={{width:'100%'}}>
                <Slider
                    min={ 1 }
                    max={ this.state.pageCount }
                    step={ 1 }
                    value={ this.state.page }
                    showValue={ false }
                    onChange={ (value) => this.setPage(value) }
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Panel
            isOpen={ this.state.showPanel }
            type={ PanelType.smallFixedFar }
            onDismiss={ this._onClosePanel }
            closeButtonAriaLabel='Close'
            onRenderFooterContent={ () => {
              return (
                  <div>
                    <DefaultButton
                        style={ { 'width': '100%', marginBottom: '5px' } }
                        onClick={ () => this.clearFilterData() }
                        iconProps={ { iconName: 'clearFilter' } }
                    >
                      Clear Filter
                    </DefaultButton>
                    <DefaultButton
                        style={ { 'width': '100%', marginBottom: '5px' } }
                        onClick={ () => this.filterData() }
                        iconProps={ { iconName: 'filter' } }
                    >
                      Filter
                    </DefaultButton>
                  </div>
              );
            } }
          >
            <form noValidate>
              <div style={styles.form}>
                <Form
                    model={this.props.model}
                    initialData={this.state.filterQuery}
                    onFormChange={(data, isValid) => this.onFilterDataChange(data, isValid)}
                    onEdit={this.state.filterQuery && this.state.filterQuery.create ? false : true}
                    filter={true}
                />
              </div>
            </form>
          </Panel>
        </div>
      </div>
    )
  }
}

const styles = {
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    // backgroundColor: colors.lightGrey,
    borderTop: `${colors.grey} thin solid`,
  },
  paginationButtons: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 200,
    alignItems: 'center'
  },
  paginationInput: {
    width:50,
    padding: 4,
    // marginTop: 3
  },
  limitInput: {
    width:85,
    padding: '5px',
    // marginTop: 3,
    display: 'flex',
    alignItems : 'center'
  },
  paginationSlider: {
    flex: 1,
    paddingRight: 10,
    display : 'flex',
    alignItems : 'center'
  }
  
}

CollectionList.propTypes = propTypes

CollectionList.defaultProps = defaultProps

export default withRouter(CollectionList)
