import React, { Component } from 'react'
import AuthService from '../auth/authService'
import { Redirect } from 'react-router-dom'

class EnsureAuthenticated extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      loggedIn: false
    }
  }
  
  async componentWillMount() {
    const loggedIn = await AuthService().isLoggedIn()
    if(!loggedIn) {
      this.setState({loggedIn: false, loading: false})
    }else {
      // await AuthService().setAuthorizationHeader()
      this.setState({loggedIn: true, loading: false})
    }
  }

  render() {
    if (!this.state.loading && !this.state.loggedIn) {
      return (
        <Redirect to={'/login'}/>
      )
    }
    return (
      <div>
        {!this.state.loading && this.props.children}
      </div>
    )
  }
}

export default EnsureAuthenticated
