import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter} from 'react-router-dom'
import { DefaultButton } from 'office-ui-fabric-react/lib/Button'
import _ from 'lodash'
import config from '../../config'
import moment from 'moment'
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { convertObjectToArray, hasAccess } from '../../helpers/utils'
import { IconButton } from 'office-ui-fabric-react/lib/Button'

import './CollectionView.scss'
const imageUrl = config.imageUrl

const propTypes = {
  collectionName: PropTypes.string,
  model: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
  hideHeader: PropTypes.bool,
  viewType: PropTypes.oneOf(['fullPage'])
}

const defaultProps = {
  hideHeader: false
}
initializeIcons(/* optional base url */);

class CollectionView extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      id: null,
      loading: false,
      data: {},
      error: false,
      fields: [],
      imageFields: [],
      printFlag: false,
      hideDialog: true,
      payment: {
        paymentMode: 'Cash'
      }
    }
    this.closeDialog = this.closeDialog.bind(this)
  }

  async componentDidMount(){
    this._isMounted = true
    let fields = convertObjectToArray(this.props.model.fields)
    fields = fields.filter(field => !field.hideInView)
    this.setState({fields: fields})
    await this.setState({id: this.props.id || this.props.match.params.id})
    await this.getData()
    await this.getImageFields()
  }

  componentWillReceiveProps(newProps){
    if(newProps.fetchNewData){
      this.getData()
    }
  }

  async getImageFields() {
    let imageFields = this.state.fields.filter((e) => e.type === 'file')
    let fields = this.state.fields
    fields = _.reject(fields, {type: 'file'})
    imageFields = _.map(imageFields, function(element){
      const imageField = element.name.replace('_id', '')
      return {...element, imageFieldName: imageField, fileType: (element.fileAcceptType ? (element.fileAcceptType === 'application/pdf' ? 'pdf' : 'image') : 'image')}
    });
    if(this._isMounted){
      await this.setState({imageFields: imageFields, fields: fields})
    }
  }

  async convertBooleanAndCollectionToValue(data) {
    let fields = _.filter(this.state.fields, (e)=> {
      return e.type === 'boolean' || e.type === 'collection' || e.type === 'date' || e.type === 'collectionArrayTag' || e.type === 'multiSelect' 
    })
    fields.map(async (field) => {
      if(field.type === 'boolean'){
        data[field.name] = (typeof data[field.name] === 'string' && parseInt(data[field.name])) ? "Yes" : "No"
      }else if(field.type === 'collection'){
        let newField = field.name.replace('_id', '')
        console.log(data)
        if(data[newField]){
          data[field.name] = data[newField].name || data[newField].title
        }
      }else if(field.type === 'date'){
        data[field.name] = data[field.name] ? moment(data[field.name]).format("MMMM Do YYYY") : ""
      }else if(field.type === 'collectionArrayTag'){
        let str = ''
        if(field.field){
          _.forEach(data[field.name], (e) => {
            let newField = field.field.replace('_id', '')
            str += (e[newField]['name'] || e[newField]['title'])+ '   '
          })
        }else if(data[field.name] && data[field.name].length){
          const result = await field.service(field.collection.url).list()
          const temp = _.filter(result, (e) => data[field.name].indexOf(e.id) != -1)
          _.forEach(temp, (e) => {
            str += (e['name'] || e['title'] ) + '   '
          })
        }
        data[field.name] = str
      }else if(field.type === 'multiSelect' && data[field.name]){
        const items = _.map(_.filter(field.options, (e) =>{
          return data[field.name].indexOf(e.key) !== -1
        }), 'text')
        data[field.name] = items.join(', ')
      }
    })
    return data
  }

  async getData() {
    try {
      if(!this.state.id) return
      if(this._isMounted){
        this.setState({loading: true})
      }
      let result = await this.props.service.get(this.state.id)
      result = await this.convertBooleanAndCollectionToValue(result)
      if(this._isMounted){
        await this.setState({data: result, loading: false})
      }
    } catch (error) {
      this.setState({error: error, loading: false})
    }
  }
  showEdit() {
    this.props.history.push(this.props.location.pathname + '/edit')
  }

  async removeItem() {
    try {
      this.setState({loading: true})
      await this.props.service.remove(this.state.id)
      this.setState({loading: false})
      this.showList()
    } catch (error) {
      this.setState({loading: false, error: error.message || 'Something went wrong'})
    }
  }
  showList() {
    let index = this.props.location.pathname.lastIndexOf('/')
    this.props.history.push(this.props.location.pathname.substring(0, index))
  }
  printItem() {
    console.log('print called')
  }
  closeDialog() {
    this.setState({hideDialog: true})
  }
  rawMarkup(val){
    return { __html: val }
  }
  componentWillUnmount(){
    this._isMounted = false
  }
  getView(field, index) {
    const type = field.type || "text"
    switch(field.type){
      case 'collectionArray':
        return (
          <tr key={index}>
            <td> {field.label || _.startCase(field.name)} </td>
            <td>
              {
                this.state.data[field.name] && this.state.data[field.name].length &&
                <table style={{border: "1px solid black"}}>
                  <thead>
                      <tr>
                        {
                          field.tableViewFields.map((f, i) => {
                            return (
                              <th key={i}>{f.label}</th>   
                            )
                          })
                        }
                      </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.data[field.name].map((val, index) => {
                        return(
                          <tr key={index}>
                            {
                              field.tableViewFields.map((f, i) => {
                                return (
                                  <td key={i}>{val[f.name]}</td>   
                                )
                              })
                            }
                          </tr>
                          )
                      })
                    }
                  </tbody>
                </table>
              }          
            </td>
          </tr>
        )
      default: 
        return (
          <tr key={index}>
            <td> {field.label || field.name} </td>
            {
              field.type == 'wysiwyg'  ?
              <td> <div className={'sk-wysiwyg-data-view'} dangerouslySetInnerHTML={ this.rawMarkup(this.state.data[field.name]) } /> </td> :
              <td> {this.state.data[field.name]} </td>              
            }
          </tr>
        )
    }
  }

  async doAction(action, id){
    const result = await this.props.service[action.action](this.state.id)
    this.getData()
  }

  render() {
    return (
        <div  id="CollectionView"  className="flex flex-1 column">
          {
            !this.props.hideHeader &&
            <div className="header">
              <div className="title">{
                this.props.title ? this.props.title : (this.props.model.name + '/' + this.state.id)
              }
              </div>
              <div className="actions">
                {
                  this.props.model.viewActions && this.props.model.viewActions.edit &&
                  <DefaultButton
                      text='Edit'
                      iconProps={ { iconName: 'Edit' } }
                      onClick={() => this.showEdit(this.props.match.params.id)}
                  />
                }
                {
                  this.props.model.viewActions && this.props.model.viewActions.remove &&
                  <DefaultButton
                      text='Delete'
                      iconProps={ { iconName: 'Trash' } }
                      onClick={() => this.removeItem(this.props.match.params.id)}
                  />
                }
                {
                  this.props.model.viewActions && this.props.model.viewActions.print &&
                  <DefaultButton
                      text='Print'
                      iconProps={ { iconName: 'Print' } }
                      onClick={() => this.printItem()}
                  />
                }
                {this.props.model.viewActions && this.props.model.viewActions.otherActions && this.props.model.viewActions.otherActions.map((action, index) => {
                  return (
                    <Fragment key={index}>
                      { hasAccess(action.authorizeRule) &&
                          <DefaultButton
                            text={action.name}
                            iconProps={ { iconName: action.icon } }
                            onClick={()=> this.doAction(action)}
                          />
                      }
                    </Fragment>
                  )
                })}
              </div>
            </div>
          }
          <div className="body-container flex">
            {
              this.state.imageFields.length > 0 &&
              <div className="section img-container">
                {
                  this.state.imageFields.map((field, index) => {
                    return (
                        <div className={'img-c'} key={index}>
                          {
                            this.state.data[field.imageFieldName] && (field.fileType === 'pdf' ?
                            <div>
                              <a href={imageUrl + this.state.data[field.imageFieldName]} download target="_blank">
                                <IconButton
                                  iconProps={ { iconName: 'download' } }
                                  title='Previous'
                                  ariaLabel='Previous'
                                />
                                <h4>{field.imageFieldName}</h4>
                              </a>
                            </div>
                            : 
                            <>
                              <img src={imageUrl + (this.state.data[field.imageFieldName].thumbUrl || this.state.data[field.imageFieldName].url || this.state.data[field.imageFieldName])}/>
                              <h4>{field.imageFieldName}</h4>
                            </>
                            )}
                        </div>
                    )
                  })
                }
              </div>
            }
            <div className="section flex-1">
              <table className={'master-table'}>
                <tbody>
                {
                  this.state.data.id && 
                    this.state.fields.map((field, index) => {
                      return (
                          this.getView(field, index)
                      )
                    })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
    )
  }
}

CollectionView.propTypes = propTypes

CollectionView.defaultProps = defaultProps

export default withRouter(CollectionView)
