import Api from '../helpers/api'
import axios from 'axios'

const URL = '/auth'

const AuthService = () => {

  const setAuthorizationHeader = async () => {
    const token = await localStorage.getItem('adminToken')
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
      delete axios.defaults.headers.common['Authorization']        
    }
    return
  }

  const login = async (email, password) => {
    const result = await Api().post(URL+'/login', {email, password})
    if(result.token) {
      await localStorage.setItem('adminToken', result.token)      
      // await localStorage.setItem('adminId', result.adminId)
      // await localStorage.setItem('admin', JSON.stringify(result.admin))
      await setAuthorizationHeader(result.token)
      return result
    } else {
      return false
    }
  }

  const logout = async () => {
    localStorage.removeItem('adminToken')
    localStorage.removeItem('adminId')
    localStorage.removeItem('admin')
    setAuthorizationHeader(false)
  }

  const isLoggedIn = async () => {
    const token = await localStorage.getItem('adminToken')
    return token?true:false
  }

  const getNewToken = async () => {
    await setAuthorizationHeader()
    // const result = await Api().post(URL+'/renew-token')
    // if(result.token) {
    //   await localStorage.setItem('token', result.token)
    //   await localStorage.setItem('userId', result.data.userId)
    //   await localStorage.setItem('companyId', result.data.companyId)
    //   await setAuthorizationHeader(result.token)
    //   return result
    // } else {
    //   logout()
    //   return false
    // }
  }

  const updateToken = async (newToken, data) => {
    await localStorage.setItem('adminToken', newToken)
    await localStorage.setItem('adimnId', data.userId)
    await localStorage.setItem('admin', JSON.stringify(data.user))
    await setAuthorizationHeader(newToken)
  }


  return {
    login,
    logout,
    isLoggedIn,
    setAuthorizationHeader,
    getNewToken,
    updateToken
  }
}

export default AuthService