import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Image } from 'office-ui-fabric-react/lib/Image'

import config from '../config'

const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.any
  ]),
  handleFileInputChange: PropTypes.func.isRequired,
  fileAcceptType: PropTypes.string,
  formData: PropTypes.object,
  field: PropTypes.string
}

const defaultProps = {
  fileAcceptType: 'image/*'
}

class FileInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: null
    }
  }

  componentDidMount() {
    this.setValue(this.props.value, this.props.formData)    
  }
  
  async componentWillReceiveProps(nextProps, oldProps) {
    if(nextProps.value !== oldProps.value) {
      this.setValue(nextProps.value, nextProps.formData)
      return       
    }
  }
 
  setValue(value, formData){
    if (value) {
      if (value instanceof File) {
        if (value.type.match('image.*')) {
          let reader  = new FileReader();
          reader.addEventListener("load",  () => {
            this.setState({value: reader.result})
          }, false);
          reader.readAsDataURL(value)
        }else {
          this.setState({value: null})
        }
      } else if(typeof value === 'object') {
        this.setState({value: config.imageUrl + 'thumb_' + value.url})        
      } else if(typeof value === 'number'){
        const field = this.props.field.replace(/Id$/, '') 
        const data = formData[field]
        if(data){
          this.setState({value: config.imageUrl + 'thumb_' + data.url})                  
        }
      } else { //string
        this.setState({value: config.imageUrl + value})
      }
    }else {
      this.setState({value: null})
    }
  }
 
  render() {
    return (
      <div>
        <input type="file" accept={this.props.fileAcceptType} onChange={this.props.handleFileInputChange} />
        {
          <Image style={{marginTop: '1em', marginBottom: '1em'}} src={this.state.value} width={ 150 } />
        }
      </div>
    )
  }
}

FileInput.propTypes = propTypes

FileInput.defaultProps = defaultProps

export default FileInput
