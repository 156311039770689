import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import './index.scss'
import App from './components/App'
import registerServiceWorker from './registerServiceWorker'
import { Fabric } from 'office-ui-fabric-react/lib/Fabric'
import { loadTheme } from 'office-ui-fabric-react/lib/Styling'
import { colors } from './theme'

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'


loadTheme({
  palette: {
    'themePrimary': colors.primary,
    'themeDark': colors.darkPrimary
  }
})

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 3000,
  offset: '30px',
  transition: transitions.SCALE
}

ReactDOM.render(
  (
    <Fabric>    
      <HashRouter>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </HashRouter>
    </Fabric>
  )
  , document.getElementById('root'))
registerServiceWorker()
