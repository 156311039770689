import React, { Component } from 'react'

class NotFoundPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <div>
        <h1>NOT FOUND</h1>
      </div>
    )
  }
}

export default NotFoundPage
