import React, { Component } from 'react'
import './UserContainer.scss'
import { Switch, Route, withRouter } from 'react-router-dom'

import TopNav from '../shared/TopNav'
import LeftNav from '../shared/LeftNav'

import NotFoundPage from './NotFoundPage'
import Dashboard from "../components/dashboard/Dashboard";

import AuthService from '../auth/authService'

import CollectionRouterBuilder from '../shared/collection/CollectionRouterBuilder'
import CollectionPageBuilder from '../shared/collection/collectionPageBuilder'
import CollectionService from '../shared/collection/collection.service'
import CollectionNew from '../shared/collection/CollectionNew'
import CollectionView from '../shared/collection/CollectionView'
import CollectionEdit from '../shared/collection/CollectionEdit'


// import ScreenViewContainer  from './screen/ScreenViewContainer'


import SettingsModel from '../collections/setting/setting.model'
// import ImageModel from '../collections/image/image.model'
// import ProfileGallery from '../components/gallery/Gallery'
// import ProfileModel from '../collections/profile/profile.model'
// import ProfileService from '../collections/profile/profile.service'

import AdminModel from '../collections/admin/admin.model'
import ProductModel from '../collections/product/product.model'
import BrandModel from '../collections/brand/brand.model'
import CategoryModel from '../collections/category/category.model'
import ProjectModel from '../collections/project/project.model'
import EnquiryModel from '../collections/enquiry/enquiry.model'
// import ProfilePage from '../components/profile/ProfilePage'
class UserContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      company: 'Globexgate',
      toastr: '',
      routes: [
        // {
        //   name: 'admin',
        //   model: AdminModel,
        // },
        {
          name: 'Products',
          model: ProductModel,
        },{
          name: 'Brands',
          model: BrandModel,
        },{
          name: 'Projects',
          model: ProjectModel,
        },{
          name: 'Categories',
          model: CategoryModel,
        },{
          name: 'enquiry',
          model: EnquiryModel,
        }
      ],
      leftNavItems : [{
        links: [
          { name: 'Home', key: 'Home', link: '/', iconProps: {
            iconName: 'home'
          },},
          { name: 'Products', key: 'Product', link: '/products', iconProps: {
            iconName: 'settings'
          } },
          { name: 'Brands', key: 'Brand', link: '/brands',iconProps: {
            iconName: 'PieSingle' }},
          // { name: 'Projects', key: 'Project', link: '/projects',iconProps: {
          //   iconName: 'IssueTracking' } },
          { name: 'Categories', key: 'Categories', link: '/categories',iconProps: {
            iconName: 'Source' } },
          { name: 'Enquiry', key: 'Enquiry', link: '/enquiry',iconProps: {
            iconName: 'TextDocumentShared' }
          },
          {name:'Settings',link:'/settings',iconProps: {
            iconName: 'settings'
          }}
        ]
      }]
    }
  }
  async logout() {
    await AuthService().logout()
    this.props.history.push('/login')
  }
  render() {
    return (
        <div id="user-container">
          <LeftNav heading={'MEPPROX BMT'} leftNavItems={this.state.leftNavItems}/>
          <div className="right-container flex column">
            <TopNav onLogout={() => this.logout()}/>
            <div className='page-container'>
              <Switch>
                {this.state.routes.map(route =>
                  <Route key={route.name} path={`/${route.name}`} render={() => 
                    <CollectionRouterBuilder key={route.name} baseRoute={route.name} model={route.model} 
                      showAlert={(message, type)=>this.props.showAlert(message, type)} service={route.model}/>
                  } />
                )
              }
              <Route exact path='/' render={()=> <Dashboard showAlert={(message, type)=> this.showAlert(message, type)}/>}/>
              {/* <Route exact path='/company-settings' render={()=> <CollectionView showAlert={(message, type)=> this.props.showAlert(message, type)}/>}/> */}
              <Route exact path='/settings' component={()=>
                  <CollectionView service={CollectionService(SettingsModel.url, {showAlert: this.props.showAlert})} fetchNewData={this.state.fetchNewData} model={SettingsModel} id={1}/>}
                />
                <Route exact path='/settings/Edit' component={()=>
                  <CollectionEdit service={CollectionService(SettingsModel.url, {showAlert: this.props.showAlert})} fetchNewData={this.state.fetchNewData} model={SettingsModel} collectionName={SettingsModel.name} id={1}/>}
                />              
              <Route component={NotFoundPage} />
              </Switch>
            </div>
          </div>
        </div>
    )
  }
}

export default withRouter(UserContainer)
