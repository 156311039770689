import axios from 'axios'
import config from '../config'
import { stringifyError } from './utils'
const apiUrl = `${config.apiUrl}/api`

const Api = () => {

  const handleError = (error) => {
    console.log(error)
    let errorObject = JSON.parse(stringifyError(error), null, '\t').response
    if (errorObject.status === 401 || errorObject.code === 'UNAUTHORIZED') {
      localStorage.removeItem('adminToken')
      // localStorage.removeItem('admin')
      // localStorage.removeItem('adminId')
      window.location.reload()
    }
  }

  const post = async (url, data) => {
    try {
      const result = await axios.post(apiUrl+url, data)
      return result.data
    }catch (error) {
      console.log(error)
      handleError(error)
    }
  }

  const postFile = async (url, data) => {    
    var tempFormData = new FormData();
    Object.keys(data).map((keyName, keyIndex) => {
      tempFormData.append(keyName, data[keyName])        
    })
    try {
      const result = await axios.post(apiUrl+url, tempFormData,{ headers: {'Content-Type': 'multipart/form-data' }})
      return result.data
    }catch (error) {
      handleError(error)
    }
  }

  const put = async (url, data) => {
    try{
      const result = await axios.put(apiUrl+url, data)
      return result.data    
    }catch (error) {
      console.log('error',error)
      handleError(error)
    }
  }
  
  const putFile = async (url, data) => {    
    var tempFormData = new FormData();
    Object.keys(data).map((keyName, keyIndex) => {
        tempFormData.append(keyName, data[keyName])        
    })
    try {
      const result = await axios.post(apiUrl+url, tempFormData,{ headers: {'Content-Type': 'multipart/form-data' }})
      return result.data
    }catch (error) {
      handleError(error)
    }
  }

  const get =  async (url, query) => {
    try{
      const result = await axios.get(apiUrl+url, {params: query})    
      return result.data
    }catch (error){
      handleError(error)
    }
  }

  const remove = async (url) => {
    try{
      const result = await axios.delete(apiUrl+url)
      return result.data
    }catch(error) {
      handleError(error)
    }
  }

  return {
    get,
    post,
    postFile,
    put,
    putFile,
    remove
  }
}

export default Api