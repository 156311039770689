const enquiry = {
    name: 'enquiry',
    url: '/enquiry',
    manualCount:true,
    listActions: {
      view: true,
      filter: true
    },  
    fields: {
      id: {
        type: 'number',
        hideInForm: true
      },
      name: {
        required: true
      },
      email: {
        type: 'email',
        required: true
      },
      phone: {
        // type: 'number'
        required: true
      },
      message: {
        type: 'multilineText',
        required: true
      },
      address: {

      },
      date: {
        type: 'date',
        hideInForm: true
      },
      status : {
        type: 'select',
        options: ['Resolved','Pending']
      }
    }
  }
  
  export default enquiry