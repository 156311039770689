import React, { Component } from 'react'
import LoginForm from './LoginForm'
import './LoginPage.scss'
import logo from '../logo.svg'
import skewbird from '../skew.png'
class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  onLoginSuccess() {
    console.log('success')
    this.props.history.push('/')
  }

  render() {
    return (
      <div id='login-page'>
        <div className='project-banner'>
          <img src={logo} width="33.3%" alt=""/>
          <div className='developer'>
            <p>CREATED BY</p>
            <img src={skewbird} width="100px" alt=""/>
          </div>
        </div>
        <div className='login-container'>
          <LoginForm onLoginSuccess={() => this.onLoginSuccess()} />
        </div>
      </div>
    )
  }
}

// const styles = {  
//   container : {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundColor: '#F2F2F2',
//     position: 'absolute',
//     height: '100%',
//     width: '100%'
//   }
// }

export default LoginPage
