
const category = {
  name: 'category',
  url: '/category',
  formInNewPage: true,
  viewInNewPage: true,
  manualCount:true,
  listActions: {
    create: true,
    edit: true,
    view: true,
    filter: true
  },
  viewActions: {
    edit: true,
    remove: true
  },
  fields: {
    id: {
      type: 'number',
      hideInForm: true
    },
    name: {
      required: true,      
      hideInFilter: true
    },
    description: {
      type: 'wysiwyg',
      hideInList: true,      
      hideInFilter: true
    },
    image_url: {
      type: 'file',
      label: 'image',
      hideInList: true,
      hideInFilter: true
    },
    brochure_url: {
      type: 'file',
      fileAcceptType: 'application/pdf',
      label: 'Brochure',
      hideInList: true,
      hideInFilter: true
    },
    // featured: {
    //   type: 'boolean'
    // },
    // status: {
    //   type: 'boolean',
    //   hideInForm: true
    // }
  }
}

export default category