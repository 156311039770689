

const settings = {
  name: 'settings',
  url: '/setting',
  formInNewPage: true,
  viewInNewPage: true,
  listActions: {
    // create: true,
    edit: true,
    view: true,
  },
  viewActions: {
    edit: true,
  },
  fields: {
    id: {
      type: 'number',
      hideInForm: true,
      hideInView: true
    },
    // name: {
    //   // required: true
    // },
    email:{
      type:'email',
      // required:true
    },
    // shortDescription: {
    //   label:'short Description',	
    //   type: 'wysiwyg',
    //   hideInList: true,
    //   hideInFilter: true
    // },   
    // description: {
    //   type: 'wysiwyg',
    //   hideInList: true,
    //   hideInFilter: true
    // },
    phone:{
      // type:'number'
    },
    sec_phone:{
      // type:'number'
    },  
    address:{
      label:'Address',
      type: "multilineText"
    }, 
    // facebookUrl:{
    //   label:'Facebook Url'
    // },
    // twitterUrl:{
    //   label:'Twitter Url' 
    // },
    // linkUrl:{
    //    label:'Link Url'
    // },		
    // instagramUrl: {
    //    label:'Instagram Url' 
    // },
    gmap_url:{
      label:'Google Map Iframe Url'
    },
    // geoLocation: {
    //   placeholder: ""
    // }
  }
}

export default settings
