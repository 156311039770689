import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Form from '../Form'
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button'
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar'
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner'
import { withRouter } from 'react-router'

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  model: PropTypes.object.isRequired,
  viewType: PropTypes.oneOf(['fullPage'])
}

const defaultProps = {}

class CollectionEdit extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      id: null,
      loading: false,
      data: {},
      isValid: false,
      error: null,
      corfirmRemoveVisible: false
    }
  }

  async componentWillMount() {
    this._isMounted = true
    await this.setState({id: this.props.id || this.props.match.params.id, model:this.props.model})
    await this.getData()
  }

  async componentWillReceiveProps(nextProps) {
    if(!this.state.id || this.state.id !== nextProps.id) {
      await this.setState({id: nextProps.id})
      await this.getData()
    }
    if(nextProps.model){
      await this.setState({model: nextProps.model})
    }
  }
  

  removeForeignFields(data) {
    let newData = {}
    _.forEach(data, (value, key) => {
      if (this.props.model.fields[key]) {
        newData[key] = value
      }
    })
    return newData
  }

  async getData() {
    try {
      if(this._isMounted){
        this.setState({loading: true,data:{}})
      }
      const result = await this.props.service.get(this.state.id)
      //remove from data which are not in model
      let data = this.removeForeignFields(result)
      if(this._isMounted){
        await this.setState({loading: false, data: data})
      }
    } catch (error) {

    }
  }

  onFormChange(data, isValid) {
    this.setState({data: data, isValid: isValid})
  }

  onClose() {
    if(this.props.onClose){
      this.props.onClose()
    }else{
      const newPath = this.props.location.pathname.replace('/edit', '')
      this.props.history.push(newPath)
    }
  }

  async submitForm(e) {
    e.preventDefault()
    try {
      this.setState({loading: true, error: null})
      const result = await this.props.service.update(this.state.id, this.state.data)
      this.setState({loading: false})
      if (this.props.onUpdateData) {
        this.props.onUpdateData(result)        
      } else { //goto view  
        let splits = this.props.location.pathname.split('/')  
        splits = splits.splice(0, splits.length -1 )
        let location = splits.join('/')
        this.props.history.push(location)
      }
    } catch (error) {
      this.setState({loading: false, error: error.message || 'Something went wrong'})
    }
  }

  async remove() {
    try {
      this.setState({loading: true})
      await this.props.service.remove(this.state.id)
      this.setState({loading: false})
      this.onClose()
      this.props.onRemoveData(this.state.id)
    } catch (error) {
      this.setState({loading: false, error: error.message || 'Something went wrong'})
    }
  }
  componentWillUnmount(){
    this._isMounted = false
  }

  render() {
    return (
      <div id="collectionEdit" className="collection-form flex-1 flex column">
        <div className="header">
          <div className="title">
          {this.props.title ? this.props.title :_.startCase(this.props.collectionName) +'/'+this.state.id}
          </div>
        </div>
        <div className="body-container">
          <form noValidate onSubmit={(e) => this.submitForm(e)}>
            <Form model={this.state.model}
                  initialData={this.state.data}
                  onFormChange={(data, isValid) => this.onFormChange(data, isValid)}
                  onEdit={true}
                  formFieldQuery={this.props.formFieldQuery}
            />
            <div className={'form-group'}>
              {
                !this.state.confirmRemoveVisible &&
                <PrimaryButton
                    style={{margin:'5px 0'}}
                    type="submit"
                    text="Update"
                    disabled={!this.state.isValid}
                />
              }
              <DefaultButton
                  style={{margin:'5px 2px'}}
                  type="button"
                  text="Cancel"
                  onClick={() => {this.onClose()}}
              />
              {
                !this.state.confirmRemoveVisible &&
                <DefaultButton
                    style={{margin:'5px 0'}}
                    type="button"
                    text="Remove"
                    disabled={this.state.confirmRemoveVisible}
                    onClick={() => {this.setState({confirmRemoveVisible: true})}}
                />
              }
              {
                this.state.confirmRemoveVisible &&
                <PrimaryButton
                    style={{margin:'5px 1px'}}
                    type="button"
                    text="ConfirmRemove"
                    onClick={() => {this.remove()}}
                />
              }
            </div>
            {
              this.state.loading &&
              <Spinner size={ SpinnerSize.xSmall } />
            }
            {
              this.state.error &&
              <MessageBar messageBarType={ MessageBarType.error }>{this.state.error}</MessageBar>
            }
          </form>
        </div>
      </div>
    )
  }
}


CollectionEdit.propTypes = propTypes

CollectionEdit.defaultProps = defaultProps

export default withRouter(CollectionEdit)
