import React, { Component } from 'react'
import {IconButton } from 'office-ui-fabric-react/lib/Button'
class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  componentDidMount() {}
  
  render() {
    return (
        <div id="Dashboard" className="flex flex-1 column " style={styles.dashboard}>
          {/* <p><b>Hi Admin</b></p> */}
          {this.props.showContainer?
          <div style={styles.dashboardContainer}>
            <div style={styles.part1}>
              <div style={{textAlign:'center',padding:'14px 0'}}>
                <div style={{fontSize:'20px'}}>20,9030,930</div>
                <div style={{fontSize:'17px'}}>visitors</div>
                <div>
                  <IconButton iconProps={{ iconName: 'BarChart4' }}  />
                  </div>
              </div>
            </div>
            <div style={styles.part2}>
              <div style={{textAlign:'center',padding:'14px 0'}}>
                <div style={{fontSize:'20px'}}>30,930,000</div>
                <div style={{fontSize:'17px'}}>users</div>
                <div>
                  <IconButton iconProps={{ iconName: 'BarChart4' }}  />
                  </div>
              </div>
            </div>
            <div style={styles.part3}>
            <div style={{textAlign:'center',padding:'14px 0'}}>
              <div style={{fontSize:'20px'}}>20,9030,930</div>
                <div style={{fontSize:'17px'}}>sales</div>
                <div>
                  <IconButton iconProps={{ iconName: 'BarChart4' }}  />
                </div>
              </div>
            </div>
            <div style={styles.part4}>
              <div style={{textAlign:'center',padding:'14px 0'}}>
                <div style={{fontSize:'20px'}}>20,9030,930</div>
                <div style={{fontSize:'17px'}}>visitors</div>
                <div>
                  <IconButton iconProps={{ iconName: 'BarChart4' }}  />
                </div>
              </div>
            </div>
          </div>
          :
            <div style={{padding:'40px',textAlign:'center'}}>welcome to admin</div>
          }
        </div>
    )
  }
}

export default Dashboard

const styles = {
  dashboard:{
    height:'100%'
  },
  dashboardContainer:{
    display: 'flex',
    flexDirection: 'row',
    padding:'20px',
    height:'150px',

  },
  part1: {
    width:'200px',
    // backgroundColor:'skyblue',
    // borderRadius:'10px',
    marginRight: '20px',
    boxShadow: '0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)'

  },
  part2:{
    width:'200px',

    // backgroundColor:'orange',
    // borderRadius:'10px',
    marginRight: '20px',
    boxShadow: '0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)'

  },
  part3:{
    width:'200px',
    boxShadow: '0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)',
    marginRight: '20px',

    // backgroundColor:'red',
    // borderRadius:'10px'
  },
  part4:{
    width:'200px',
    boxShadow: '0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)',

    // border:'1px solid rgba(0,0,0,0.1)',
    // border:'1px solid black',
    // backgroundColor:'red',
    // borderRadius:'10px'
  }

} 