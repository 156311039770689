import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../theme'
import CollectionList from './CollectionList'
import CollectionNew from './CollectionNew'
import CollectionEdit from './CollectionEdit'
import { withRouter } from 'react-router'

const propTypes = {
  model: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
  collectionName: PropTypes.string.isRequired
}

const defaultProps = {}

class CollectionPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newData: null,
      editFormVisible: false,
      editItemId: null
    }
  }
  
  onNewData(data){
    this.setState({newData: data})
  }

  onUpdateData(data){
    this.setState({updatedData: data})
  }

  onRemoveData(id) {
    this.setState({removedData: id})
  }

  async handleListItemClick(id) {
    if(this.props.model.viewInNewPage) {
      this.props.history.push(this.props.location.pathname + '/' + id)
    }else {
      await this.setState({editFormVisible: true, editItemId: id})
    }
  }

  render() {
    return (
        <div id="CollectionPage" className="flex flex-1">
          {
            !this.props.model.formInNewPage && !this.state.editFormVisible &&
            <CollectionNew
                collectionName={this.props.collectionName}
                formFieldQuery={this.props.formFieldQuery}
                model={this.props.model}
                initialData={this.props.listQuery}
                service={this.props.service}
                onNewData={(data) => this.onNewData(data)}
            />
          }
          {
            !this.props.model.formInNewPage && this.state.editFormVisible &&
            <CollectionEdit
                collectionName={this.props.collectionName}
                formFieldQuery={this.props.formFieldQuery}
                model={this.props.model}
                service={this.props.service}
                id={this.state.editItemId}
                onClose={() => {this.setState({editFormVisible: false})}}
                onUpdateData={(data) => this.onUpdateData(data)}
                onRemoveData={(id) => this.onRemoveData(id)}
            />
          }
          <CollectionList
              model={this.props.model}
              service={this.props.service}
              query={this.props.listQuery}
              newData={this.state.newData}
              updatedData={this.state.updatedData}
              removedData={this.state.removedData}
              onItemClick={(id) => this.handleListItemClick(id)}
              listHiddenFields={this.props.listHiddenFields}
          />
        </div>
    )
  }
}

CollectionPage.propTypes = propTypes

CollectionPage.defaultProps = defaultProps

export default withRouter(CollectionPage)
