import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Form from '../Form'
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button'
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar'
import { withRouter } from 'react-router-dom'

const propTypes = {
  collectionName: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  service: PropTypes.object,
  viewType: PropTypes.oneOf(['fullPage']),
  onNewData: PropTypes.func
}

const defaultProps = {}

class CollectionNew extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  componentWillMount() {
    this._isMounted = true
  }
  
  async onFormChange(data, isValid) {
    this.setState({data: data, isValid: isValid})
    if(this.props.selectedItem){
      await this.setState({data:{...data,['parentId']:this.props.selectedItem.id}})
    }
  }
  async componentWillReceiveProps(newProps){
    if(newProps.initialData){
      await this.setState({data: newProps.initialData})
    }
    if(newProps.model){
      await this.setState({model: newProps.model})
    }
  }

  submitForm = async (e) => {
    e.preventDefault()
    this.setState({loading: true, error: null})
    try {
      const result =  await this.props.service.create(this.state.data)
      this.setState({loading: false})
      this.setState({data: {}})
      if(this.props.onNewData) {
        this.props.onNewData(result)
      }else {
        let index = this.props.location.pathname.lastIndexOf('/')
        this.props.history.push(this.props.location.pathname.substring(0, index))
      }
    } catch (error) {
      this.setState({loading: false, error: error.message || 'Something went wrong'})
    }
  }
  componentWillUnmount(){
    this._isMounted = false
  }

  render() {
    return (
        <div id="CollectionNew"  className="collection-form">
          <div className="header">
            <div className="title">
              New {_.startCase(this.props.collectionName) || ''}
            </div>
          </div>
          <div className="body-container">
            <form onSubmit={(e)=>this.submitForm(e)} noValidate>
              <Form
                  model={this.props.model}
                  selectedItem={this.props.selectedItem}
                  initialData={this.state.data ||  this.props.initialData}
                  onFormChange={(data, isValid) => this.onFormChange(data, isValid)}
                  formFieldQuery={this.props.formFieldQuery}
              />
              <div className="form-group">
                <PrimaryButton
                    style={{margin:'5px 0', width:'100%'}}
                    type="submit"
                    text="Create"
                    disabled={!this.state.isValid || this.state.loading}
                />
              </div>
              {
                this.state.loading &&
                <MessageBar>Please wait..</MessageBar>
              }
              {
                this.state.error &&
                <MessageBar messageBarType={ MessageBarType.error }>{this.state.error}</MessageBar>
              }
            </form>
          </div>
        </div>
    )
  }
}

const styles = {

}

CollectionNew.propTypes = propTypes

CollectionNew.defaultProps = defaultProps

export default withRouter(CollectionNew)
