import React, { Component } from 'react'
import { Nav } from 'office-ui-fabric-react/lib/Nav';
import { withRouter } from 'react-router';
import './LeftNav.scss'

class LeftNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  onLinkClick(event, element) {
    this.props.history.push(element.link);
  }

  render() {
    return (
      <div id="LeftNavContainer">
        <a href='/' className="LeftHeader">
          {this.props.heading}
        </a>
        <div className="LeftNav">
          <Nav
              groups={this.props.leftNavItems}
              onLinkClick={this.onLinkClick.bind(this)}
          />
        </div>
      </div>
    )
  }
}
export default withRouter(LeftNav)
