import React, { Component, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import LoginPage from '../auth/LoginPage'
import UserContainer from './UserContainer'
import AuthService from '../auth/authService'
import EnsureAuthenticated from './EnsureAuthenticated'
import { useAlert } from 'react-alert'


const App = () => {
  useEffect(() => {
    AuthService().getNewToken()
  }, []);
  
  const alert = useAlert()
  const showAlert = (message, type) => {
    // alert[type || 'show'](message,{
    //   onClose: () => {
    //     // console.log('closed')
    //   } 
    // })
  } 

  return (
    <Switch>
      <Route exact path='/login' component={LoginPage} />
      <EnsureAuthenticated>
        <Route path='/' render={() => <UserContainer showAlert={showAlert}/>} />
      </EnsureAuthenticated>
    </Switch>
  )
}

export default App
